
import React, {useState, useEffect} from 'react';
import configData from "../../../config.json";
// import 'bootstrap/dist/css/bootstrap.min.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Layout from '../Layout';
import logo from '../../../assets/img/logo2.png';

function OilDeal() {
    const [disableBtn, setDisableBtn] = useState(false);
    const [load, setLoad] = useState(true);
    const [data, setData] = useState([]);
    const [dataNew, setNewData] = useState([]);
    const [content, setContent] = useState("");
    const [artisan, setArtisan] = useState([]);

    const [modalShow, setModalShow] = useState(false);

    const [title, setTitle] = useState("")
    const [offerPage, setOfferPage] = useState(true);
    const [pageChaged, setPageChaged] = useState("details")
  
  
    const [show, setShow] = useState(false);
  
    const handleClose = () => {
      setOfferPage(true);
      setRequestStatus(false);
      setShow(false);
    }
    const handleShow = (data) =>{
         setData(data);
         getSingleRequest(data);
         getReqInitialOffers(data._id);
         setShow(true);
    };


  const [state, setState] = useState([])
  const [request, setRequest] = useState([]);
  const [reqOffers, setReqOffers] = useState([]);
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reqStatus, setRequestStatus] = useState(false);

  const userId = window.localStorage.getItem("userId");

  const getReq = () => {
    setIsLoading(true);
    const type = "oilDeal"
    
    return fetch(`${configData.TEST_URL}/energieRequestForm/${userId}/${type}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": window.localStorage.getItem("token") 
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
        setIsLoading(false)
          setRequest(responseJson.data);
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error);
      });
  }


  const getSingleRequest = (data) => {
    console.log(data._id)
    
    return fetch(`${configData.TEST_URL}/requestForm/getSingle/${data._id}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token": window.localStorage.getItem("token") 
        },
      })
      .then((response) => response.json())
      .then((responseJson) => {
          console.log(responseJson.data);
          if(responseJson.data.status === "success") setRequestStatus(true);
          
      })
      .catch((error) => {
        setIsLoading(false)
        console.error(error);
      });
  }


  const getState = () => {
       
    return fetch(`${configData.TEST_URL}/state`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token":  window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          console.log(responseJson)
            setState(responseJson.state);
        })
        .catch((error) => {
          console.error(error);
        });
  }

  const getReqInitialOffers = (reqId) => {

    setLoad(true);
       
    return fetch(`${configData.TEST_URL}/energieOffer/initial/${reqId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-auth-token":  window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson)
        setOffers(responseJson.data);
      })
      .catch((error) => {
        console.error(error);
      });
    

  }

  useEffect(() => {
    getReq();
    getState();
  },[]);


  const [msg, setMsg] = useState('')
  const [q0, setQ0] = useState('');
  const [q1, setQ1] = useState('');
  const [q2, setQ2] = useState('');
  const [q3, setQ3] = useState('');
  const [q4, setQ4] = useState('');
  const [q5, setQ5] = useState('');
  const [amount, setAmount] = useState();
  const [selectedState, setSelectedState] = useState("");

  const formSubmit = () => {
    
    setDisableBtn(true);  
        
    const token =  localStorage.getItem('token');
    
     fetch(`${configData.TEST_URL}/energieRequestForm/${userId}`, {
       method: "post",
       headers: {
         Accept: "application/json",
         "Content-Type": "application/json"
       },
       body: JSON.stringify({
            q0,q0,
            q1:q1,
            q2:q2,
            q3:q3,
            q4:q4,
            q5:q5,
            amount:amount,
            type:"oilDeal",
            stateId:selectedState
       })
     })
       .then(response => {
         return response.json();
       })
       .then(responseJson => {
  console.log(responseJson)

          setDisableBtn(false);

         if (responseJson.status === "success") {
                setQ0('');
                setQ1('');
                setQ2('');
                setQ3('');
                setQ4('');
                setQ5('');
                setSelectedState();
                alert(responseJson.message)
                setModalShow(false)
         }

         if (responseJson.status === 'error') {
           // this.setState({ disableBtn: false });
              setMsg(responseJson.message)
           return

         };
       })
       .catch(
         error => console.log(error.message)
         );

        

  }

  const viewOffer = (data) => {
    setOfferPage(false);
     setNewData(data)

    
    return fetch(`${configData.TEST_URL}/energieOffer/user/${data.requestId}/${data.artisanId}/${userId}`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-auth-token": window.localStorage.getItem("token") 
          },
        })
        .then((response) => response.json())
        .then((responseJson) => {
            setLoad(false);
            console.log(responseJson.data)
            setReqOffers(responseJson.data);
        })
        .catch((error) => {
          setLoad(false)
          console.error(error);
        });
  
  }

  const addOffer = () => {

    setDisableBtn(true);  
    // return console.log(JSON.stringify(dataNew))
     console.log(dataNew.requestId + ": " + dataNew.artisanId)
    
    fetch(`${configData.TEST_URL}/energieOffer/user/add/${userId}/${dataNew.requestId}/${dataNew.artisanId}`, {
            method: "post",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-auth-token": window.localStorage.getItem("token") 
            },
            body: JSON.stringify({
              amount:amount,
              content:content
            })
          })
       .then(response => {
         return response.json();
       })
       .then(responseJson => {

  setDisableBtn(false);

         if (responseJson.status === "success") {
            console.log(responseJson.message)
             setAmount('')
             setContent("")
             setNewData(responseJson.data);
             viewOffer(dataNew);
             
         }

         if (responseJson.status === 'error') {
              setMsg(responseJson.message)
           return

         };
       })
        .catch((error) => {
            setDisableBtn(false)
            console.log(error.message)
        });
    
  }

  const acceptOffer = (data) => {

    setDisableBtn(true);


    return fetch(`${configData.TEST_URL}/energieOffer/user/acceptOffer/${userId}/${data.requestId}/${data.artisanId}`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-auth-token": window.localStorage.getItem("token") 
        },
        body: JSON.stringify({            
            amount:data.amount
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisableBtn(false);
         if(responseJson.status === "success"){ 
               alert(responseJson.message);
              //  console.log(responseJson.data)
         }
         if (responseJson.status === "error") {
            setDisableBtn(false);
            setMsg(responseJson.message);
         }
     })
     .catch((error) => {
         console.log(error);
     });

  }
  const goBack = () => {
    window.location = window.location.origin + "/";
	}


  return (
   <>
    
    <Layout>

            <div>


            <div className='row mNew-2'>
                <div className="col-8">
                 <div className="row" style={{
                    width:'100%',
                    paddingLeft: '20px',
                    paddingTop: '1%',
                    height:'75%',
                    backgroundColor: '#ffd200',
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15}}>
                            <span  onClick={goBack} id="back">
                            <i class="fa fa-arrow-left d-flex justify-content-center backIcon"></i> 
                            </span>
                            <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>Oil and Gas Deal
                            </p>
                    </div>
                    </div>
                    <div className="col-4">
                            <img src={logo} className="img-fluid rounded mx-auto d-block vIcon"/>
                     </div>
                </div>
            



                        <div className="col-md-12 pt4">
                              <span onClick={() => setModalShow(true)} className="btn btn-success btn-sm btn-warn" style={{float: 'right'}}>Make Request</span>
                            </div>

                            {isLoading ? (<> 

                                <div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
                                <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                </div>
                                </div>

                                </>) 

                                : (<>

                    <div className="col-md-12 pt5">
                                                                                
                        {request && request.length > 0
                                ? request.map((r, index) => {
                                return <>
                                <div key={index + 1} onClick={()=> handleShow(r)} className="order">
                                        <div className="orderProduct">
                                        <p className="title">{r.q1}</p>
                                        </div>
                                    <div className="orderDate">
                                        <p className="date">12 July 23</p>
                                      
                                    </div>
                                    </div>

                                </> })
                                :<section className="container mt-5">
                                <div className="row justify-content-center align-items-center">
                                        <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Deal Found</p>
                                    </div>
                                </section>}
                  </div>

                    </>)}
                        
                      

                      {/* Successfull Modal */}
                <div class="modal fade success-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
                  <div class="modal-dialog bottom modal-lg">  
                    <div class="modal-content">
                      <div class="modal-header">
                        <p class="" id="myModalLabel">Successfull</p>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                      </div>
                                    <div class="row">
                                        <div className="col-md-2"></div>
                                        <div className="col-md-8 ml-lg-3">
                                              <img src="./img/Success.png" width="100" class="d-inline-block align-top" id='success' alt="" />
                                                <div class="col-md-12 mt-2">
                                                  <p style={{fontSize:20}}>Item Added, continue to list your items until you are done, then click on the basket to purchase them</p>
                                                </div>
                                                <div class="col-md-12 mt-2">

                                                    <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>CONTINUE SHOPPING</>)}</button>
                                                </div>
                                      </div>
                                        <div className="col-md-2"></div>
                                    </div>
                    </div>
                  </div>
                </div>



            </div>


     </Layout>

<Modal show={modalShow}  onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Make Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

          { msg == `"selectedArtisanState" is not allowed to be empty` && 
              <span style={{color:"red"}}> This field is required.</span>
              }

            { msg == `"q1" is not allowed to be empty` && 
              <span style={{color:"red"}}> This field is required.</span>
              }
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>What products do you need?</Form.Label>
              <Form.Control
                type="text"
                placeholder="" 
                autoFocus value={q1} onChange={(e) => setQ1(e.target.value)} />
            </Form.Group>

            { msg == `"q2" is not allowed to be empty` && 
                  <span style={{color:"red"}}>This field is required.</span>
                  }
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>What quantity/quality of products do you need?</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                 value={q2} onChange={(e) => setQ2(e.target.value)}/>
            </Form.Group>

            { msg == `"q3" is not allowed to be empty` && 
              <span style={{color:"red"}}> This field is required.</span>
              }
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Where do you want the products to discharge at?</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                  value={q3} onChange={(e) => setQ3(e.target.value)}/>
            </Form.Group>

            { msg == `"q4" is not allowed to be empty` && 
              <span style={{color:"red"}}> This field is required.</span>
              }
              { msg == `"q4" must be a number` && 
                <span style={{color:"red"}}> This field must be a number.</span>
                }
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>How long do you have to give for delivery?</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                  value={q4} onChange={(e) => setQ4(e.target.value)}/>
            </Form.Group>

            { msg == `"q5" is not allowed to be empty` && 
              <span style={{color:"red"}}> This field is required.</span>
              }
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>How much are you paying for the product per litre or per MT?</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={amount} onChange={(e) => setAmount(e.target.value)}/>
            </Form.Group>

            { msg == `"q5" is not allowed to be empty` && 
              <span style={{color:"red"}}> This field is required.</span>
              }
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>How are you paying ?</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={q5} onChange={(e) => setQ5(e.target.value)}/>
            </Form.Group>
            
            { msg == `"q0" is not allowed to be empty` && 
              <span style={{color:"red"}}> This field is required.</span>
              }
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>How long is the payment going to take ?</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={q0} onChange={(e) => setQ0(e.target.value)}/>
            </Form.Group>
            

            <div class="form-group">
                <label for="exampleForm2">Which State do you require this service?</label>
              { msg == `"stateId" is not allowed to be empty` && 
                <span style={{color:"red"}}> This field is required.</span>
                }
                <select class="browser-default custom-select" name="selectedState" value={selectedState} onChange={(e) => setSelectedState(e.target.value)}>
                    <option selected>Choose State here</option>
                    {state.map(c => (
                        <option value={c._id}>{c.name}</option>
                        ))}
                    </select>
                </div>


          </Form>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" size="sm" onClick={() => setModalShow(false)}>
            Close
          </Button> */}
          <Button variant="primary" size="sm" className='btn-warn'  onClick={formSubmit} disabled={disableBtn}>
            {disableBtn ? "Please wait..." : "Submit"} 
          </Button>
        </Modal.Footer>
      </Modal>

    
<Modal  show={show} onHide={handleClose} placement={"end"} >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
  
        {offerPage ? <> 


               <div className="box-parent px-4 mt-4">
                        <div onClick={()=> setPageChaged("details")}>
                            <h6 className={(pageChaged === "details") ? "mb-4 tabColor" : "mb-4"}>Request Details</h6>
                        </div>
                        <div  onClick={()=> setPageChaged("offer")} >
                            <h6 className={(pageChaged === "offer") ? "mb-4 tabColor" : "mb-4"}>Request Offers</h6>
                        </div>
                </div>
  
       {(pageChaged === "details") ?
       
            <div className="questions">
            
            <div className={"qBox"}>
                        <p className={"qText"}>What products do you need?</p>
                        <p className={"answer"}>{data.q1}</p>
                    </div>

                    <div className={"qBox"}>
                        <p className={"qText"}>What quantity/quality of products do you need?</p>
                        <p className={"answer"}>{data.q2}</p>
                    </div>

                    <div className={"qBox"}>
                        <p className={"qText"}>Where do you want the products to discharge at?</p>
                        <p className={"answer"}>{data.q3}</p>
                    </div>

                    <div className={"qBox"}>
                        <p className={"qText"}>How long do you have for delivery?</p>
                        <p className={"answer"}>{data.q4} </p>
                    </div>

                    <div className={"qBox"}>
                        <p className={"qText"}>How much are you paying for the product per litre or per MT?</p>
                        <p className={"answer"}>{data.amount} </p>
                    </div>


                    <div className={"qBox"}>
                        <p className={"qText"}>How are you paying?</p>
                        <p className={"answer"}>{data.q5} </p>
                    </div>
                
                    <div className={"qBox"}>
                        <p className={"qText"}>Which State do you require this service?</p>
                        <p className={"answer"}>{data.stateName} </p>
                    </div>
            
            </div>

           :<>

            <div>

                                                        
            {offers && offers.length > 0
                    ? offers.map((o, index) => {
                    return <>

                        <div key={index + 1} className={"counterReply pt-4"}>
                            <div className={"counterTop"}>
                                <p className={"amount"}>#{o.amount}</p>
                                <p className={"newDate"}>{o.createdAt}</p>
                            </div>
                                <div className={"counterOffer"}>
                                    <a onClick={() => viewOffer(o)}  class="btn-sm">View Offer</a>
                                </div> 
                            </div>

                    </> })

                    :<section className="container mt-5">
                    <div style={{display:"flex"}} className="w-100 justify-content-center align-items-center">
                            <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Offer Yet</p>
                        </div>
                    </section>}


            </div>
            

            </> }

      </> : <>


      <div className="moreDetails">

            <div style={{display: 'flex', flexDirection:"row", justifyContent: 'center', alignItems: 'center'}}>

                <span onClick={() => setOfferPage(true)}>close</span>

            </div>


        <div style={{overflowY:"auto", height:"1200px", overscrollBehavior:"contain"}}>

            {/* <div className={"counterReply pt-4"}>
                <div className={"counterTop"}>
                    <p className={"amount"}>#{data.q4}</p>
                    <p className={"newDate"}>12-june-23</p>
                </div>
                    <p className={"content"}>{data.q1}</p>
                    {(data.userId === "") &&
                    <div className={"counterOffer"}>
                        <button type="button" class="btn btn-success btn-sm  btn-warn">Accept Offer</button>
                    </div> }
                </div> */}

                                                
            {reqOffers && reqOffers.length > 0
                    ? reqOffers.map((req, index) => {
                    return <>

            <div key={index + 1} className={`${(req.postedBy !== req.userId) ? "vendorOffer vColor pt-4" : "vendorOffer  pt-4" }`}>

                <div className={"vCounterTop"}>
                    <p className={"amount"}>#{req.amount}</p>
                    <p className={"newDate"}>{req.createdAt}</p>
                </div>
                         <p className={"content"}>{req.content}</p>
                 {(req.status === true) ?<>
              
                      {(req.postedBy !== req.userId) ? <>


                        {!reqStatus ? <> 
        
                    <div className={"counterOffer"}>
                        <button onClick={()=> acceptOffer(req)} type="button" className="btn btn-success btn-sm btn-warn">Accept Offer</button>
                     </div>
                       </> : null }

                       </> : null }

                  </> : null}

             </div>


                    </> })

                    :<section className="container mt-5">
                    <div style={{display:"flex"}} className="w-100 justify-content-center align-items-center">
                            <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Offer Yet</p>
                        </div>
                    </section>}

              </div>


       </div>

            <div  style={{width:"100%", backgroundColor:"#fff", display: "flex", flexDirection: "column", position:"absolute", bottom: "0px", width:"100%"}}>

              {(data.status === "success") ? <>


      {reqStatus ? <> 
              
              <div style={{display: 'flex', flexDirection:"column", flexWrap:"wrap", justifyContent:"center", alignItems: 'center', padding:"10% 0"}}>
                   <h5>This request is no longer available</h5>
              </div>

       </> :<>

            <div style={{width:"90%"}}>
                                
                <FloatingLabel 
                    controlId="floatingInput"
                    label="whats your offer? (Amount)"
                    className="mb-3">
                    <Form.Control type="number" placeholder="whats your offer" value={amount} onChange={(e) => setAmount(e.target.value)} />
                </FloatingLabel>
                
                <FloatingLabel controlId="floatingTextarea2" label="Leave a comment here">
                    <Form.Control
                    as="textarea"
                    placeholder="Leave a comment here"
                    style={{ height: '100px' }}   value={content} onChange={(e) => setContent(e.target.value)}
                    />
                </FloatingLabel>
            </div>
            <div style={{width:"100%"}}>
            <Button onClick={addOffer} variant="success" className='btn-warn' size="sm">
                Submit
                </Button>
            </div>

            </>}

            </> : null}



            </div>
                
                
      
         </>}
          
        </Modal.Body>
      </Modal>



   </>
  );
}

export default OilDeal;
