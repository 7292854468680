import React, {useEffect, useState} from 'react';
import Header from './header';
import Footer from './Footer';
import image from '../assets/img/image.png';
import configData from "../config.json";
import { useForm } from "react-hook-form";

const Login = () => {

    const { register, handleSubmit, watch, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);


  const loginUser = (data) => {

    setDisablebtn(true);


    return fetch(`${configData.SERVER_URL}/auth/login`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({            
            phoneNumber:data.phoneNumber,
            password:data.password
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
         if(responseJson.status === "success"){
            window.localStorage.setItem("token", responseJson.data.token);
            window.location = window.location.origin + "/Account/Dashboard";
         }
         if (responseJson.status === "error") {
            setDisablebtn(false);
            alert(responseJson.message);
             setMessage(responseJson.message);
         }
     })
     .catch((error) => {
         console.log(error);
     });
}

    return (


    <div style={{backgroundColor:"#fff"}}> 

        <Header/>

        <div class="col-md-12"> 
         <div class="row">
             <div class="col-md-1"></div>
             <div class="col-md-5 mt-4">
                    <div class="col-md-12 mt-3">                                        
                   
                        <form onSubmit={handleSubmit(loginUser)} enctype="multipart/form-data" id="submit" class="border border-light p-4" style={{backgroundColor:"#fff"}}>

                               {/* <div class="alert alert-warning" role="alert">
                                   {message.length ? ({message}) : (null)}
                                    </div>        */}
                            <p class="h4 mb-4">Welcome Back</p>    
                            <div class="form-group">
                                <input type="text" id="FormEmail" class="form-control mb-4 textinp" placeholder="Phone Number" name="phoneNumber" ref={register({ required: true, })} />
                                {errors.phoneNumber && <div class="alert alert-danger" role="alert">Phone Number Required</div>}
                            </div>

                            <div class="form-group">
                                <input type="password" id="FormPassword" class="form-control textinp" placeholder="Password" name="password" ref={register({ required: true, })} />
                                {errors.password && <div class="alert alert-danger" role="alert">Password Required</div>}
                            </div>
                                
                                <div class="form-group">
                                        <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light">
                                              {disablebtn ? (<>Please wait</>) : (<>LOGIN</>)}
                                        </button>
                                        
                                </div>


                                <p>Forgotten Password? 
                                    <a href="">Click Here</a>
                                    </p>

                                <hr/>

                                <p>If you dont have an account already you can't
                                    <a href="/Register"> <em>Sign In</em></a>
                                    </p>

                        </form>
                    </div>
                  </div>
             <div class="col-md-6">
                 <img src={image} class="img-fluid d-block"  id='login-image' width="100%"/>                
             </div>
             </div> 
         </div>



         <Footer/>


        </div>




    );
  

}

export default Login;