import React, {useContext, useState, useEffect} from 'react';

import {Context} from "../../../Store";
import configData from "../../../config.json";
function Product() {

    const [state, setState] = useContext(Context);
    const [prod, setProd] = useState([]); 
    const [loading, setLoading] = useState(true);

    const getProducts = async () => {
        return fetch(`${configData.SERVER_URL}/products`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token": window.localStorage.getItem("token"),
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            console.log(responseJson)
            if (responseJson.status === "success") {
              console.log(responseJson)
              setProd(responseJson.data);
              setLoading(false)
            }
            if (responseJson.message === "error") {
                if (responseJson.message === "token is invalid" || responseJson.message === "no token provided") {
                  alert("Invalid")
                }
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    
    useEffect(() => { 
        const interval = setInterval(() => {
          getProducts();
          }, 3000);
          return () => clearInterval(interval);
      }, []);

    const setProduct = (name, id, costPerUnit, image, unit) => {
     
        window.localStorage.setItem("productId", id);
        window.localStorage.setItem("pricePerLitre", costPerUnit);
        window.localStorage.setItem("productSelected", name);
        window.localStorage.setItem("productUnit", unit);
        window.localStorage.setItem("productSelectedImage", image);
        window.localStorage.setItem("productObject", state.products.find(p => p.name === name));
    
        setState({
          ...state,
          productId: id,
          pricePerLitre: costPerUnit,
          productSelected: name,
          productUnit: unit,
          productSelectedImage: image,
          productObject: state.products.find(p => p.name === name),
        });
        // alert(state.productSelectedImage);
        
        window.location = window.location.origin + "/MakeOrder";
    
      }
   
  
    return (
        <>

  
    {loading ? (<> 
    <div className="col-12" style={{marginTop:'5%'}}>
        <div class="d-flex justify-content-center">
            <div class="spinner-grow" role="status">
                <span class="sr-only">Loading...</span>
            </div> 
        </div>
        </div>
            </>) : (<>
            
    {prod.map(s => (
      
            <div className="cardTwo cad" key={s._id} onClick={()=> setProduct(s.name, s._id, s.costPerUnit, s.image, s.unit)} style={{cursor:'pointer'}}>
                <img src={`${configData.SERVER_URL}/images/products/${s.image}`} className="img-fluid mx-auto d-block prodImg"/>
                <p style={{color: "#000", fontSize: "15px", fontWeight: "bold", textAlign:'center'}}>
                      {s.name }</p>
            </div>
            
       ))}


        </>)}

        </>
    );
}

export default Product;