import React, {useContext, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from "react-hook-form";
import { usePaystackPayment  } from 'react-paystack';
import {Context} from "../../Store";
import configData from "../../config.json";
import logo from '../../assets/img/logo2.png';
import { getParsedCommandLineOfConfigFile } from 'typescript';



const {REACT_APP_PAYMENT_API_URL} = process.env;

function Payment() {
    const history = useHistory();
    const { register, handleSubmit, watch, errors } = useForm();
    const [state, setState] = useContext(Context);
    const [time, setTime] = useState(new Date());
    const [disableBtn, setDisableBtn] = useState(false);

    
    const totalAmount = localStorage.getItem('totalAmount');
    
    const back = () =>{
        window.location = window.location.origin + "/OrderSummary";
     }
     const reference = window.localStorage.getItem("reference");
     const email = window.localStorage.getItem("email");

  
 
    const config = {
        reference: reference,
        email: email,
        amount: totalAmount * 100,
        publicKey: REACT_APP_PAYMENT_API_URL,
    };
    
    // you can call this function anything
    const onSuccess = (reference) => {
        
      console.log(reference);


    //   localStorage.getItem("order");
    //   localStorage.getItem("transaction");


        return fetch(`${configData.SERVER_URL}/transactions/verify`, {
          method: "post",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
            "x-api-token": window.localStorage.getItem("token"),
          },
            body: JSON.stringify({       
              reference: reference
            })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log(responseJson)

            if(responseJson.status === "error"){
              console.log(responseJson.message);
          }

          if (responseJson.status === "success") {
              setDisableBtn(false)
              if(state.litres > 30){
              alert('Thanks Transaction Successful. Your order cannot be delivered by our bike agents, we will manually send it to you');
              window.location = window.location.origin + "/Transactions";    
              }else{
              if (state.order.shouldDispatch) {
                history.push('/')
                // ('QrcodeDetails', { qrvalue: s._id, date:s.scannedAt}
                      window.location = window.location.origin + "/SearchAgent";
                  } else {
                  // navigate somewhere else 
                  alert('Thank Your Transaction Successful. We will deliver to your location at your scheduled time');
                  window.location = window.location.origin + "/Transactions";
              }
              }
          }

        })
        .catch((error) => {
            console.log(error);
        });



    };
  
    // you can call this function anything
    const onClose = () => {
      // implementation for  whatever you want to do when the Paystack dialog closed.
      console.log('closed')
    }
  
    const PaystackHookExample = () => {
        const initializePayment = usePaystackPayment(config);
        return (
          <div>
              <button class="btn btn-warn my-4 btn-block waves-effect waves-light" onClick={() => {
                  initializePayment(onSuccess, onClose)
              }}>Pay Now</button>
          </div>
        );
    };

    useEffect(()=>{
    //  alert(REACT_APP_PAYMENT_API_URL);
    })

    return (
        <>
            



        <div>
            
                
              <PaystackHookExample />                
                                    
                          

        </div>
  

        </>
    );
}

export default Payment;