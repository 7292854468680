import React, { useEffect, useContext } from 'react';
import io from "socket.io-client";
import { useHistory, useParams } from 'react-router-dom';
import Map from '../component/Map';
import logo from '../../assets/img/agentD1.png';
import {Context} from "../../Store";
import configData from "../../config.json";

const socket = io('https://api.energie.agent01.online');
// export const socket = socketIOClient.connect(SERVER_URL);
// export const SocketContext = React.createContext();

function SearchAgent() {
    const history = useHistory();
    const [state, setState] = useContext(Context);

     const orderId = localStorage.getItem('orderId');

        
     useEffect(() => { 
        const token =  window.localStorage.getItem("token");
        if (!token || !orderId){ 
            window.location = window.location.origin + "/";
            return
        }
      }, []);    
   


  const checkAgent = async () => {
    try {

        return fetch(`${configData.SERVER_URL}/orders/${orderId}/agent`, {
            method: "get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "x-api-token": window.localStorage.getItem("token"),
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson.status === "success") {
                       history.push('DeliveryAgent')
                }
            })
            .catch((error) => {
              console.error(error);
            });
            } catch (e) {
        }
  }


     useEffect(() => {
        socket.on("order.assignmentAccepted", (payload) => {
        const order = payload.order
        const agent = payload.agent
        const orderId = payload.order._id
        setState(state => ({
            ...state,
            orderDetails: order,
            agentDetails: agent
        }))

        history.push('DeliveryAgent')
        })
        return () => {
        socket.off("order.assignmentAccepted")
        }
    }, [])
  
  useEffect(() => {
    return () => {
      if (history.action === "POP") {
        alert('Please Note!", "If you go back, you can still track your order on your transaction page.');
        window.location = window.location.origin + "/Transactions";
        return
      }
    };
  }, [history])
 


    return (
        <>


 <section class="contain">
     <div className="row">
         <section className="box1">

         </section>


         <section className="box2">

                <div className='row mNew-2'>
                            <div className="col-2">
                            </div>
                            <div className="col-8">
                                  <img src={logo} className="img-fluid rounded mx-auto d-block" width="60%" style={{marginTop:"60%"}}/>
                                  <p style={{color: '#000', fontSize:'15px', textAlign: 'center', marginTop: '5%'}}>Searching For An Agent Close To Your Location.</p>
                                    <div class="d-flex justify-content-center">
                                            <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                            </div> 
                                        </div>
                                </div>
                            <div className="col-2">
                            </div>
                  </div>
            
            

         </section>


         <section className="box1">

         </section>
     </div>
 </section>
 

            
        </>
    );
}

export default SearchAgent;