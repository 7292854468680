import React, { useState, useEffect, useContext } from 'react';
import GPlace from './GPlaces';
import {Context} from "../../../Store";

// API key of the google map
const GOOGLE_MAP_API_KEY = 'AIzaSyDbZOsA6Oo5dfsG4HE9jkUYmEznCwiZrEY';
 
// load google map script
const loadGoogleMapScript = (callback) => {
  if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=places`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
}
 

function GMap() {
  const [isLoaded, setIsLoaded] = useState(true);
  const [loadMap, setLoadMap] = useState(false);
  const [state, setState] = useContext(Context);
 
  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true)
    });
  }, []);
  
 
  return (
    <div className="App">
      {!loadMap ? 
         <div className="col-12" style={{marginTop:'50%'}}>
             <div class="d-flex justify-content-center">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div> 
                </div>
            </div> : <> 
       
       <div>
          <GPlace/> 
       </div>

      
      </>}
    </div>

  );
}

export default GMap;