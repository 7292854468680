import React from 'react';
import logo from '../../assets/img/logo2.png';

function About() {


    const back = () =>{
        window.location = window.location.origin + "/#profile";
     }

    return (
        <>


 <section class="contain">
     <div className="row">
         <section className="box1">

         </section>


         <section className="box2">

            <div className='row mNew-2'>
                    <div className="col-8">
                    <div className="row" style={{
                        width:'100%',
                        paddingLeft: '20px',
                        paddingTop: '1%',
                        height:'75%',
                        backgroundColor: '#ffd200',
                        borderTopRightRadius: 15,
                        borderBottomRightRadius: 15}}>
                                <span onClick={()=> back()} id="back">
                                <i class="fa fa-arrow-left d-flex justify-content-center backIcon"></i> 
                                </span>
                                <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>About Energie
                                </p>
                        </div>
                        </div>
                        <div className="col-4">
                                <img src={logo} className="img-fluid rounded mx-auto d-block vIcon"/>
                        </div>
                    </div>

                    <div className="col-12">

          <div >

                    <p style={{fontSize:18, fontWeight:'bold',textAlign:'center'}}>Energie is a mobile application that enables more coverage and easy access of petroleum products to the consumers/customers.</p>
                    <p className="p-2 text-center">For the first time in Africa, customers will have the upper hand and purchase petroleum products/derivatives at their convenience, saving time for them and journey</p>

                    <p className="p-2 text-center" >We are a non-traditional diversity tech company with emphasis on social responsibility.</p>

                    <p className="p-2 text-center" >Our objective is to use technology to reach out to the community in which we operate and aid them in achieving sustainable development.</p>

                    <p  className="p-2 text-center">We have adopted United Nations Educational, Scientific and Cultural Organization(UNESCO) Sustainable Development Goals(SDG) as our vision standards. </p>
                    <p className="p-2 text-center" >Energie Will be able to contribute to the communities it operates in to achieve (no poverty, zero hunger, good health and well- being, Quality education ,gender equality, reduce inequality, sustainable cities and communities, industry innovation and infrastructure, affordable and clean energy decedent work and economic growth, climate action) among others.</p>

            </div>

                    </div>
            
            

         </section>


         <section className="box1">

         </section>
     </div>
 </section>
 

            
        </>
    );
}

export default About;