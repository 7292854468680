import React, {Component, useState} from 'react';
import Header from './header';
import Footer from './Footer';
import image from '../assets/img/image.png';
import data from "../main/data/area.json";
import groupData from "../main/data/group.json";

import configData from "../config.json";

const CreateAccount = () => {
    const [isloading, setIsloading] = useState(false)

   const showImage = () => {

        if(this.files && this.files[0])
            {
                var obj = new FileReader();
                obj.onload = function(data){
                    var image = document.getElementById("avatar");
                    image.src = data.target.result;
                    image.style.display = "block";
    
                }
                obj.readAsDataURL(this.files[0]);
            }
    
    
    }

    const regAgent = (e) => {
        setIsloading(true);

        e.preventDefault()

        const form = new FormData(document.getElementById("submit"))

            return fetch(`${configData.SERVER_URL}/auth/agent/apply`, {
                method: "post",
                headers: {
                    Accept: "application/json"
                },
                body:form
            })
            .then((response) => response.json())
            .then((responseJson) => {

                if(responseJson.status === "success"){
                    setIsloading(false);

                    alert(responseJson.message);
                }
                if (responseJson.status === "error") {
                    setIsloading(false);
                    alert(responseJson.message);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }

    
    return (


    <div style={{backgroundColor:"#fff"}}> 

        <Header/>

        <div class="col-md-12"> 
         <div class="row">
             <div class="col-md-3">               
             </div>
             <div class="col-md-6 mt-5 mb-5">
                            <form onSubmit={regAgent} enctype="multipart/form-data" id="submit" class="border border-light p-4">

                                            <p class="text-center h4 mb-4">Alfa Agents Registration Form</p>
                                         

                                            <div class="form-row mb-4 text-center">
                                                <div class="col-md-3"></div>
                                                <div class="col-md-6">
                                                    <img src="img/user.png" class="img-fluid" id="avatar"/>
                                                    <small  class="form-text text-muted">Please upload Full Picture without makeup/black shades or caps etc. (Natural looks)</small>
                                                </div>
                                                <div class="col-md-3"></div>
                                                <div class="col-md-3"></div>
                                                <div class="col-md-6 mt-2">
                                                    <input type="file" name="avatar" placeholder="choose image" onChange=""/>
                                                </div>
                                                <div class="col-md-3"></div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col-6">
                                                    <input type="text" id="defaultRegisterFormFullName" class="form-control" placeholder="First Name" name="firstName"/>
                                                </div>
                                                <div class="col-6">
                                                    <input type="text" id="defaultRegisterFormFullName" class="form-control" placeholder="Last Name" name="lastName"/>
                                                </div>
                                            </div>



                                            <div class="form-row m-1">
                                                <div class="col">
                                                    <input type="email" id="defaultRegisterFormEmail" class="form-control mb-4" placeholder="E-mail" name="email"/>
                                                </div>
                                                <div class="col">
                                                    <input type="text" class="form-control" placeholder="Phone number (whatsapp line)" aria-describedby="defaultRegisterFormPhoneHelpBlock" name="phoneNumber"/>
                                                </div>
                                            </div>  

                                            <div class="form-row m-1 mb-2">
                                                
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                         <label for="sel1">Sex</label><br/>
                                                         <div class="form-check-inline">
                                                              <label class="form-check-label">
                                                                <input type="radio" class="form-check-input" name="sex" value="male"/>Male
                                                              </label>
                                                            </div>
                                                            <div class="form-check-inline">
                                                              <label class="form-check-label">
                                                                <input type="radio" class="form-check-input" name="sex" value="female"/>Female
                                                              </label>
                                                            </div>
                                                        </div>
                                                </div>
                                                <div class="col-md-6">
                                                 <label for="dateofbirth">Date Of Birth</label>
                                                     <input type="date" id="dateofbirth" class="form-control" placeholder="Date Of Birth" name="dateOfBirth"/>
                                                </div>
                                            </div>  
                                         
                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="text" id="defaultRegisterOccupation" class="form-control" placeholder="Nationality" name="nationality"/>
                                                </div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col-6">
                                                    <input type="text" id="defaultRegisterFormNationality" class="form-control" placeholder="State of Origin" name="origin"/>
                                                </div>
                                                <div class="col-6">
                                                    <input type="text" id="defaultRegisterFormNationality" class="form-control" placeholder="Local Government" name="local"/>
                                                </div>
                                                <div class="col-12">
                                                    <input type="text" id="defaultRegisterFormBirth" class="form-control" placeholder="Home town/ village community/family name" name="placeOfBirth"/>
                                                </div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col mt-2">
                                                    <textarea type="text" id="defaultRegisterFormEmail" class="form-control" placeholder="Current Residential Address in PHC" rows="5" name="residentialAddress"></textarea>
                                                </div>
                                            </div>  

                                        <div class="form-row m-1">
                                            <div class="col">
                                                <small  class="form-text text-muted">Please upload utility bill with letter from the name on it accepting relationship with you</small>
                                            </div>
                                            <div class="col">
                                                    <input type="file" name="utilityBill" placeholder="choose File" />
                                            </div>
                                        </div>  

                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="text" id="defaultRegisterFormID" class="form-control" placeholder="Identification Number (from Goverment approved IDs only)" name="identificationNumber"/>
                                                </div>
                                            </div>


                                        <div class="form-row m-1">
                                            <div class="col">
                                                <small  class="form-text text-muted">Please upload Religious organization (church) leader's letter of recommendation for you.</small>
                                            </div>
                                            <div class="col">
                                                    <input type="file" name="recommendation" placeholder="choose File" />
                                            </div>
                                        </div>  

                                        <div class="form-row m-1">
                                            <div class="col-12 mt-4">
                                                <small  class="form-text text-muted">Tell us a brief story of you. We are not focused on your educational background to meet up to the job requirements but will still like to know relevant information about you.</small>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <textarea type="text" class="form-control" placeholder="Write here....." rows="5" name="aboutYou"></textarea>
                                            </div>
                                        </div>  


                                     <div class="form-row m-1 mb-2">
                                                
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label for="sel1">Select the Group your area falls under</label><br/>
                                                  <select class="form-control" name="area">
                                                    {groupData.map(group => (
                                                        <option value={group}>{group}</option>
                                                    ))}
                                               </select>

                                              </div>
                                         </div>
                                    </div>

                                        <div class="form-row m-1 mb-2">
                                                    <div class="col-12 mt-3">
                                                        <small  class="form-text text-muted">How well do you know this area?</small>
                                                    </div>
                                                    <div class="col-12 mt-2">
                                                        <textarea type="text" class="form-control" placeholder="Write here....." rows="10" name="areaDesc"></textarea>
                                                    </div>
                                            </div>  


                                        <div class="form-row m-1">
                                            <div class="col-12 mt-3">
                                                <small  class="form-text text-muted">Agents with vehicles should indicate with details of their vehicles. (optional)</small>
                                            </div>
                                            <div class="col-12 mt-2">
                                                <textarea type="text" class="form-control" placeholder="Write here....." rows="5" name="vehicles"></textarea>
                                            </div>
                                        </div>  

                                           
                                            <button class="btn btn-warn my-4 btn-block waves-effect waves-light" type="submit" name="add">
                                               {isloading ? (<> Loading...</>) : (<> Register</>)} 
                                            </button>
                                        

                                            <p class="text-center m-2">By clicking
                                                <em> Register</em> you agree to be an Agent
                                                <a href="" target="_blank"> terms of service</a>
                                             </p>

                                        </form>
                                    

             </div>
             <div class="col-md-3">               
             </div>
          </div> 
         </div>



         <Footer/>


        </div>




    );

}

export default CreateAccount;