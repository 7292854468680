import React, {useEffect, useState} from 'react';
import configData from "../../config.json";
import { useForm } from "react-hook-form";

const DeleteAcc = () => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, handleSubmit, watch, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);
    const [login, setlogin] = useState(true);
    const [reg, setreg] = useState(false);
    const [registerUser, setregisterUser] = useState(false);


  const loginUser = async(data) => {

    setDisablebtn(true);
    return fetch(`${configData.SERVER_URL}/auth/login`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({             
            phoneNumber:data.phoneNumber,
            password:data.password
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
       if(responseJson.status === "success"){
          alert("Account Deleted")
       }
       if (responseJson.status === "error") {
          setDisablebtn(false);
          alert(responseJson.message);
           setMessage(responseJson.message);
       }
     })
     .catch((error) => {
         console.log(error);
     });
   }


const registerPage = () =>{
    setlogin(false);
    setreg(true);
}
const loginPage = () =>{
    setreg(false);
    setlogin(true);
}


    
useEffect(() => {
         
    const token =  localStorage.getItem('token');
    // alert("responseJson hjkjlnkm")

    if(!token){
        handleShow();
    }

},[])





    return (

<>


        <div className="col-12">
         <div class="row">
             <div class="col-md-2"></div>
             <div class="col-md-8 mb-4">
                    <div class="col-md-12 mt-3">                          
                        <form onSubmit={handleSubmit(loginUser)} enctype="multipart/form-data" id="submit" class="p-4" style={{backgroundColor:"#fff"}}>
                            <p class="h4 mb-4 mt-5">Enter Phone Number and password to delete account</p>    
                            <div class="form-group mb-4">
                                <input type="text" id="FormEmail" class="form-control mb-4" placeholder="Phone Number" name="phoneNumber" ref={register({ required: true, })} />
                                {errors.phoneNumber && <div class="alert alert-danger" role="alert" style={{fontSize:12}}>Phone Number Required</div>}
                            </div>

                            <div class="form-group">
                                <input type="password" id="FormPassword" class="form-control" placeholder="Password" name="password" ref={register({ required: true, })} />
                                {errors.password && <div class="alert alert-danger" role="alert" style={{fontSize:12}}>Password Required</div>}
                            </div>
                                
                                <div class="form-group">
                                        <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" >
                                              {disablebtn ? (<>Please wait</>) : (<>Delete</>)}
                                        </button>
                                        
                                </div>


                        </form>

                   </div>
                  </div>
             <div class="col-md-2">              
             </div>
         </div> 

        </div>

</>

    );
  

}

export default DeleteAcc;