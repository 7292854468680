import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { useHistory } from 'react-router-dom';
import userPic from '../../assets/img/user.png';
import {Context} from "../../Store";
import configData from "../../config.json";

import Marker from './component/Marker.tsx';
 
const {REACT_APP_API_URL} = process.env;
 
const DeliveryAgent = () => {

    const history = useHistory();
    const [center, setCenter] = useState([]);
    const [zoom, setZoom] = useState(15);

    const lat = localStorage.getItem("lat");
    const long = localStorage.getItem("long");
       
    const productSelectedImage = localStorage.getItem('productSelectedImage'); 
    const productSelected = localStorage.getItem('productSelected');
       

    const [mapView, setMapView] = useState(null);
    const [distance, setDistance] = useState("");
    const [time, setTime] = useState("");
    const [origin, setOrigin] = useState({    
    })
    const [userLat, setUserLat] = useState(0)
    const [userLong, setUserLong] = useState(0)
    const [agentfirstName, setagentfirstName] = useState('');
    const [agentlastName, setagentlastName] = useState('');
    const [agentPhn, setAgentPhn] = useState();
    const [agentLat, setAgentLat] = useState(0)
    const [agentLong, setAgentLong] = useState(0)
    const [avatar, setAvatar] = useState(userPic);
    const [paymentMethod, setpaymentMethod] = useState()
    const [confirmationCode, setconfirmationCode] = useState()
  
    const [destination, setDestination] = useState({
      latitude: 0,
      longitude: 0
    })

    const orderId = localStorage.getItem('orderId');


    const checkAgent = async () => {  
        try {
    
            return fetch(`${configData.SERVER_URL}/orders/${orderId}/agent`, {
                method: "get",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "x-api-token": window.localStorage.getItem("token"),
                },
              })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === "success") {
                        setOrigin(responseJson.data.location);
                        setagentfirstName(responseJson.data.firstName);
                        setagentlastName(responseJson.data.lastName);
                        setAgentPhn(responseJson.data.phoneNumber);
                        setAgentLat(responseJson.data.location.latitude);
                        setAgentLong(responseJson.data.location.longitude);
                        if (responseJson.data && responseJson.data.avatar) {
                          setAvatar({
                            uri: configData.SERVER_URL + "/images/avatars/" + responseJson.data.avatar
                          })
                        }
                    }
                })
                .catch((error) => {
                  console.error(error);
                });
                } catch (e) {
            } 

      }
    
      const checkOrder = async () => {
        try {
            return fetch(`${configData.SERVER_URL}/orders/${orderId}`, {
                method: "get",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  "x-api-token": window.localStorage.getItem("token"),
                },
              })
                .then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === "success") {
                        if (responseJson.data.status === "delivered") {
                        //   setModalVisible(false) take user to a successful page
                          history.push('/HomeApp');
                        }
                      
                    }
                })
                .catch((error) => {
                  console.error(error);
                });
            } catch (e) {
            console.error(e)
            }
        }
  
      useEffect(() => {
        setDestination({
          latitude: lat,
          longitude: long
        })
        checkAgent()
    
        const interval = setInterval(() => {
          checkOrder()
        }, 3000)

    
        if (agentPhn && avatar) {
          setAvatar({
            uri: configData.SERVER_URL + "/images/avatars/" + avatar
          })
        }
    
        return () => {
          clearInterval(interval)
        }
      }, [])
   
      useEffect(() => { 
        const token =  window.localStorage.getItem("token");
        if (!token || !orderId){ 
            window.location = window.location.origin + "/";
            return
        }

    
      }, []);    
   

      const modal = () =>{
        window.$(".bs-example-modal-lg").modal("show") 
    }


 
    return (<>

        <section class="contain">
        <div className="row">
            <section className="box1">
   
            </section>
   
   
            <section className="box2" style={{maxHeight:'100vh'}}>

            <div style={{ height: '75vh', width: '100%' }}>
                <div style={{ height: '100%', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: `${REACT_APP_API_URL}`}}
                        defaultCenter={{lat: parseFloat(lat), lng: parseFloat(long)}}
                        defaultZoom={zoom}>
                        {/* customer marker */}
                        <Marker
                            lat={lat}
                            lng={long}
                            text="My Marker"/>

                            {/* agents marker */}
                            <Marker
                                lat={agentLat}
                                lng={agentLong}
                                text="My Marker"/>


    {/* <MyDirectionsRenderer
      origin={{ lat: 27.71415, lng: -82.3583 }}
      destination={{ lat: 42.681339, lng: -89.026932 }}
      travelMode={google.maps.TravelMode.DRIVING}
    /> */}

                        </GoogleMapReact>
                </div>
   
            </div>

            <div className="col-12" style={{ height: '25vh', backgroundColor:'#fff', borderTopRightRadius: 25,
            borderTopLeftRadius: 25, paddingTop:'2%', paddingBottom:"5%", paddingLeft:"5%", paddingRight:"5%", borderColor:'#000', borderWidth:6}}>

                <div className="ro mt-4">

                   {/* <div className="col-1"></div> */}
                    <div className="col-3">
                       <img src={avatar} class="img-fluid rounded mx-auto d-block" width="80%" id="delivAgent"/>
                    </div>
                    <div className="col-7" style={{paddingHorizontal: 20, justifyContent: 'center'}}>
                        <p style={{fontSize: '1rem', color:'#000', fontWeight: '700', textAlign:'left', margin:0}}>{agentfirstName} {agentlastName}</p>
                        <span style={{color: '#a2a2a3'}}>{agentPhn}</span>
                    </div>
                    <div className="col-2">
                       <a href={`tel:${agentPhn}`}>
                          <i class="fa fa-phone mt-2" aria-hidden="true" style={{color:'#ffd200', float:'right', fontSize:35, }}></i>
                        </a>
                    </div>


                    <div className="col-12 mt-2">
                       <div className="row">
                           <div className="col-1"></div>
                           <div className="col-9" style={{backgroundColor:'#ffd200', height:'auto', borderRadius:10, padding:'1%'}}>
                               <div className="ow d-flex justify-content-center text-center">

                          <div className="col-4">
                               <div className="row d-flex justify-content-center">
                                    <i class="fa fa-clock-o mt-2" aria-hidden="true" style={{color:'#000', fontSize:35}}></i>
                                 </div>
                                 <p style={{fontSize: 9, color:'#000', fontWeight: '700', margin:0}}>mins</p>
                          </div>

                        <div className="col-4">
                               <div className="row d-flex justify-content-center">
                                    <i class="fa fa-tachometer mt-2" aria-hidden="true" style={{color:'#000', fontSize:35 }}></i>
                                    </div>
                                 <p style={{fontSize: 9, color:'#000', fontWeight: '700', margin:0}}>km</p>
                         </div>
                        <div className="col-4">
                            <img src={`${configData.SERVER_URL}/images/products/${productSelectedImage}`} className="img-fluid mx-auto d-block" id="prodDelImg"/>
                            <p style={{fontSize: 9, color:'#000', fontWeight: '700', margin:0, textAlign:'center'}}>{productSelected}</p>
                                   </div>
                               </div>
                           </div>
                           <div className="col-1 mt-3 pl-4" onClick={()=> modal()}>
                                    <i class="fa fa-qrcode d-flex justify-content-center backCode"></i> 
                            </div>

                       </div>
                    </div>

                  </div>
                
            </div>




            <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
          <div class="modal-dialog bottom qrCode modal-lg">  
            <div class="modal-content">
                            <div class="row">
                                <div className="col-md-3"></div>
                                <div className="col-md-6">

                                <div className="col-12 mt-4">
                                     <h4 className="dText"  style={{fontWeight:'bold', marginTop:'9%', textAlign:'center'}}>Payment Method</h4>
                                      <h4 className="dTextTwo"  style={{fontWeight:'bold', float:'none', textAlign:'center'}}>{paymentMethod}</h4>

                                  </div>
                                        <div class="col-md-12 mt-2">
                                         
                                        <h4 className="dText"  style={{fontWeight:'bold', marginTop:'9%', textAlign:'center'}}>Delivery Code</h4>
                                         <h4 className="dTextTwo"  style={{fontWeight:'bold', float:'none', textAlign:'center'}}>{confirmationCode}</h4>
                                        </div>
                                  </div>
                                <div className="col-md-3"></div>
                            </div>
            </div>
          </div>
        </div>
       




   
   
             </section>
   
            <section className="box1">
   
            </section>
        </div>
    </section>

  
  
 </> );
  
}
 
export default DeliveryAgent;