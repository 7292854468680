import React, {useEffect, useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import configData from "../../../config.json";
import { useForm } from "react-hook-form";

const Login = () => {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { register, handleSubmit, watch, errors } = useForm();
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);
    const [login, setlogin] = useState(true);
    const [reg, setreg] = useState(false);
    const [registerUser, setregisterUser] = useState(false);


  const loginUser = (data) => {

    setDisablebtn(true);
    return fetch(`${configData.SERVER_URL}/auth/login`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({             
            phoneNumber:data.phoneNumber,
            password:data.password
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
       if(responseJson.status === "success"){
        window.localStorage.setItem("token", responseJson.data.token);
        window.localStorage.setItem("firstName", responseJson.data.account.firstName);
        window.localStorage.setItem("lastName", responseJson.data.account.lastName);
        window.localStorage.setItem("email", responseJson.data.account.email);
        window.localStorage.setItem("phoneNumber", responseJson.data.account.phoneNumber);
        handleClose();
       }
       if (responseJson.status === "error") {
          setDisablebtn(false);
          alert(responseJson.message);
           setMessage(responseJson.message);
       }
     })
     .catch((error) => {
         console.log(error);
     });
   }
  
   const userReg = (data) => {
    
    setDisablebtn(true);

    return fetch(`${configData.SERVER_URL}/auth/register`, {
        method: "post",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            firstName:data.firstName,
            lastName:data.lastName,
            email:data.email,
            type: 'user',
            phoneNumber:data.phoneNumber,
            password:data.password,
            religiousOrganization:data.religiousOrganization             
        })
     })
     .then((response) => response.json())
     .then((responseJson) => {
       console.log(responseJson)
       setDisablebtn(false);
         if(responseJson.status === "success"){

            window.localStorage.setItem("token", responseJson.data.token);
            window.localStorage.setItem("firstName", responseJson.data.account.firstName);
            window.localStorage.setItem("lastName", responseJson.data.account.lastName);
            window.localStorage.setItem("email", responseJson.data.account.email);
            window.localStorage.setItem("phoneNumber", responseJson.data.account.phoneNumber);
            window.localStorage.setItem("userId", responseJson.data.account._id);
            
             handleClose();
            
         }
         if (responseJson.status === "error") {
            setDisablebtn(false);
            alert(responseJson.message);
             setMessage(responseJson.message);
         }
     })
     .catch((error) => {
        setDisablebtn(false);
         console.log(error);
     }).finally(() => {
        setDisablebtn(false);
    });
}

const registerPage = () =>{
    setlogin(false);
    setreg(true);
}
const loginPage = () =>{
    setreg(false);
    setlogin(true);
}


    
useEffect(() => {
         
    const token =  localStorage.getItem('token');
    // alert("responseJson hjkjlnkm")

    if(!token){
        handleShow();
    }
    // if(token){
    //     console.log("responseJson")

    // return fetch(`${configData.SERVER_URL}/profile`, {
    //     method: "get",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       "x-api-token": window.localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => response.json())
    //     .then((responseJson) => {
    //         console.log(responseJson)
    //       if (responseJson.message === "error") {
    //           if (responseJson.message === "token is invalid" || responseJson.message === "no token provided") {
    //             alert("Invalid")
    //           }
    //       }
    //     })
    //     .catch((error) => {
    //       console.error(error);
    //     });
    // }

},[])





    return (

<>


 <Modal show={show} onHide={handleClose}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>
         <div class="row">
             <div class="col-md-2"></div>
             <div class="col-md-8 mb-4">
                    <div class="col-md-12 mt-3">    
                    {login &&   (<>                                 
                        <form onSubmit={handleSubmit(loginUser)} enctype="multipart/form-data" id="submit" class="p-4" style={{backgroundColor:"#fff"}}>

                               {/* <div class="alert alert-warning" role="alert">
                                   {message.length ? ({message}) : (null)}
                                    </div>        */}
                            <p class="h4 mb-4 mt-5">Please Login to Continue</p>    
                            <div class="form-group mb-4">
                                <input type="text" id="FormEmail" class="form-control mb-4" placeholder="Phone Number" name="phoneNumber" ref={register({ required: true, })} />
                                {errors.phoneNumber && <div class="alert alert-danger" role="alert" style={{fontSize:12}}>Phone Number Required</div>}
                            </div>

                            <div class="form-group">
                                <input type="password" id="FormPassword" class="form-control" placeholder="Password" name="password" ref={register({ required: true, })} />
                                {errors.password && <div class="alert alert-danger" role="alert" style={{fontSize:12}}>Password Required</div>}
                            </div>
                                
                                <div class="form-group">
                                        <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" >
                                              {disablebtn ? (<>Please wait</>) : (<>LOGIN</>)}
                                        </button>
                                        
                                </div>


                                <p>Forgotten Password? 
                                    <span style={{color:'#e0b802', cursor:'pointer'}} href="dashboard"> Click Here</span>
                                    </p>

                                <hr/>

                                <p>If you dont have an account already you can
                                    <a onClick={()=> registerPage()}> <em style={{color:'#e0b802', cursor:'pointer'}} >Register Here</em></a>
                                    </p>

                        </form>

                     </>)}
                     
            {reg && ( <> 

                     <form  onSubmit={handleSubmit(userReg)} enctype="multipart/form-data" id="submit" class="p-4">

                     <p class="h4 mb-4 mt-5">Create Account</p>
                
                     <div class="form-row m-1">
                         <div class="col-12">
                             <input type="text" id="defaultRegisterFormFull" class="form-control" placeholder="First Name" name="firstName" ref={register({ required: true, })} />
                             {errors.firstName && <div class="alert alert-danger" role="alert">First Name Required</div>}
                         </div>
                         <div class="col-12">
                             <input type="text" id="defaultRegisterFormName" class="form-control" placeholder="Last Name" name="lastName"  ref={register({ required: true, })} />
                             {errors.lastName && <div class="alert alert-danger" role="alert">Last Name Required</div>}
                         </div>
                     </div>

                     <div class="form-row m-1">
                         <div class="col-12">
                             <input type="email" id="defaultRegisterFormEmail" class="form-control mb-4" placeholder="E-mail" name="email" ref={register({ required: true, })} />
                             {errors.email && <div class="alert alert-danger" role="alert">Email Required</div>}
                         </div>
                         <div class="col-12">
                             <input type="text" id="defaultRegisterPhonePassword" class="form-control" placeholder="Phone number" name="phoneNumber"  ref={register({ required: true, })} />
                             {errors.phoneNumber && <div class="alert alert-danger" role="alert">Phone Number Required</div>}
                         </div>
                         <div class="col-12">
                             <input type="password" id="defaultRegisterForm" class="form-control mb-4" placeholder="Password" name="password" ref={register({ required: true, })} />
                             {errors.password && <div class="alert alert-danger" role="alert">Password Required</div>}
                         </div>
                        <div class="col-12">
                             <input type="text" id="defaultRegisterFormReligious" class="form-control mb-4" placeholder="Religious Organization (Optional)" name="religiousOrganization"/>
                        </div>
                      </div>                                         
                  
                      <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light">
                       {disablebtn ? (<>Please wait</>) : (<>Register</>)}
                     </button>
                     <hr/>

                            <p style={{textAlign:'center'}}>already have an account
                                <span style={{color:'#e0b802', cursor:'pointer'}} onClick={()=> loginPage()}> <em>Login</em></span>
                            </p>

                 </form>
             


                 </> )  }
                   
                   </div>
                  </div>
             <div class="col-md-2">              
             </div>
         </div> 

        </Modal.Body>
   </Modal>

</>

    );
  

}

export default Login;