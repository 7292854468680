import React, {useState} from 'react';
const initialState = {
  type: '',
  email: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  password: '',
  products: [],
  productSelected: '',
  productSelectedImage: '',
  productId: '',
  litres: '',
  pricePerLitre: '',
  productUnit:'',
  date: '',
  deliveryTime: '',
  destination:{
    longitude: 0,
    latitude: 0,
  },
  address: '',
  deliveryCoordinates: '',
  order: '',
  orderId: '',
  totalAmount: '',
  deliveryFee: '',
  currentLocation: {
    longitude: 0,
    latitude: 0,
  },
  transId: '',
};



export const Context = React.createContext();

const Store = ({children}) => {
    const [state, setState] = useState(initialState);

    return (
        <Context.Provider value={[state, setState]}>
           {children}
        </Context.Provider>
    );
};


export default Store;