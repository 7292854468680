import React, {useState, useEffect}  from 'react';
import {Link} from "react-router-dom";
import userPic from '../../../assets/img/user.png';
import logo from '../../../assets/img/logo2.png';
import configData from "../../../config.json";

function ProfileTab() {

    const [avatar, setAvatar] = useState('');

    const getProfile = async () => {
      
        return fetch(`${configData.SERVER_URL}/profile`, {
          method: 'get',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'x-api-token': window.localStorage.getItem("token"),
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
                if (responseJson.status === 'success') {
                     if(responseJson.data.avatar){
                        setAvatar(responseJson.data.avatar)
                    }
                }
          })
          .catch((error) => {
            console.error(error);
          });
      };

    useEffect(() => {
        getProfile();
      }, [])

  const firstName =  window.localStorage.getItem("firstName");
  const lastName =  window.localStorage.getItem("lastName");
  const phoneNumber =  window.localStorage.getItem("phoneNumber");

  const navig = (link) =>{
    window.location = window.location.origin + `/${link}`;
  }

  const logout =() =>{
    localStorage.clear();
    window.location = window.location.origin + "/";
}


    return (
        <div>


     <div className='row'>
          <div className="col-8">
            <div style={{
              width:'100%',
              paddingLeft: '20px',
              paddingTop: '1%',
              height:'75%',
              backgroundColor: '#ffd200',
              borderTopRightRadius: 15,
              borderBottomRightRadius: 15}}>
                  <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>
                    PROFILE
                  </p>
              </div>
          </div>
          <div className="col-4">
                    <img src={logo} class="img-fluid rounded mx-auto d-block vIcon"/>
          </div>
        </div>

        <div className="buttonView">
            <div className="buttonViewOne">
                <div style={{alignItems: 'center', height: '35%', paddingTop:15}}>
                    
                    <img src={(avatar) ? avatar : userPic} class="img-fluid rounded mx-auto d-block" width="20%"/>
                    <p style={{marginTop: 10, fontSize: 15, textAlign:'center'}}>{firstName} {lastName}</p>
                    <p style={{fontSize: 12, textAlign:'center'}}>{phoneNumber}</p>
                </div>
            </div>
        </div>


        <div className="container">
            <div className="row mt-3" onClick={()=> navig("EditProfile")} style={{cursor:'pointer'}}>
                <div className="col-4">
                    <i class="fa fa-user-plus d-flex justify-content-center" style={{fontSize: '1.5rem', color:'#ffd200', float:'right'}}></i> 
                </div>
                <div className="col-8">
                    <span style={{fontSize: 15, fontWeight:500}}>Edit Profile</span>
                </div>
            </div>
            <div className="row mt-3" onClick={()=> navig("Transactions")} style={{cursor:'pointer'}}>
                <div className="col-4">
                    <i class="fa fa-credit-card d-flex justify-content-center" style={{fontSize: '1.5rem', color:'#ffd200', float:'right'}}></i> 
                </div>
                <div className="col-8">
                    <span style={{fontSize: 15, fontWeight:500}}>Transactions</span>
                </div>
            </div>
            <a href="https://wa.me/+2348096137067/?text=urlencodedtext" className="row" style={{cursor:'pointer', color:'#212529'}}>
                <div className="col-4 mt-3 hideDesktop">
                    <i class="fa fa-sticky-note d-flex justify-content-center" style={{fontSize: '1.5rem', color:'#ffd200', float:'right'}}></i> 
                </div>
                <div className="col-8 mt-3 hideDesktop">
                    <span style={{fontSize: 15, fontWeight:500}}>Contact Support</span>
                </div>
            </a>
            <div className="row mt-3" style={{cursor:'pointer'}} onClick={()=> navig("FAQ")}>
                <div className="col-4">
                    <i class="fa fa-bell d-flex justify-content-center" style={{fontSize: '1.5rem', color:'#ffd200', float:'right'}}></i> 
                </div>
                <div className="col-8">
                    <span style={{fontSize: 15, fontWeight:500}}>FAQ</span>
                </div>
            </div>
            <div className="row mt-3" style={{cursor:'pointer'}} onClick={()=> navig("About")}>
                <div className="col-4">
                    <img src={logo} class="img-fluid rounded mx-auto d-block" id="abtImg"/>
                </div>
                <div className="col-8">
                    <span style={{fontSize: 15, fontWeight:500}}>About Energie</span>
                </div>
            </div>
            <div className="row mt-3" style={{cursor:'pointer'}} onClick={()=> logout()}>
                <div className="col-4">
                    <i class="fa fa-sign-out d-flex justify-content-center" style={{fontSize: '1.5rem', color:'#ffd200', float:'right'}}></i> 
                </div>
                <div className="col-8">
                    <span style={{fontSize: 15, fontWeight:500}}>Logout</span>
                </div>
            </div>

        </div>



            
        </div>
    );
}

export default ProfileTab;