import React, {Component} from 'react';
import {Link} from "react-router-dom";
import Header from './header';
import Footer from './Footer';
import delivery from '../assets/img/DELIVERY.png';
import agent from '../assets/img/agent-reg.png';

export class Agent extends Component {

    
  render() {

    return (


    <div> 

        <Header/>


        
             
            
        <div class="container-fluid" id="agent-1">
                  <div class="container">
                        <div class="row align-items-center">

                            <div class="col-md-8 abt-m">
                                <strong class="pull-right  wow fadeInUp animated" data-wow-delay="0.2s" id="welcome">Our agents will be mobilised with  a bicycle  to transport  product from the seller to the customer to buyer.  </strong>
                                <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}>  The agents will have all complete protective gears and will be trained to make deliveries.  They will be able to make an average  of #30,000.00 weekly from participating in making product  deliveries  to our customers. Agent will be both male and female and Will accommodate ages from 15 year and above.</p>
                                <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}> The agents will undergo at least two weeks of training by our trainers.  They will be given the bicycles, gears and technological devices to enable an efficient  service delivery and standards. Our app will be able to track  the movement  of the agent making delivery  and record destinations and directions  made by the agents on the process. The agent will be given our customers  containers to make deliveries with and pumps to transfer products  into the customers tanks/storage.</p>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="" style={{float: "left", clear: "both"}}>
                                    <img src={delivery} class="img-fluid rounded mx-auto d-block" width="80%"/>
                                    </div>
                                </div>
                         </div>
                   </div>
              </div>
    
    
           <section class="container-fluid" id="agent-2">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-md-5">
                            <div class="skl-mbl-img-2">
                              <img src={agent} class="img-fluid rounded mx-auto d-block" width="100%" alt=""/>
                                </div>
                        </div>
                        <div class="col-md-7">
                            <div class="sec-titile-wrapper">                              
                              <strong class="  wow fadeInUp animated" data-wow-delay="0.2s" id="welcome">HOW TO BECOME AN AGENT </strong>
                                <div class="" style={{float: "left", clear: "both"}}></div>
                              <div class="border1" style={{margin: "0 0 25px"}}></div>
                               
                            <div class="row"  style={{float: "left", clear: "both"}}>
                                <div class="col-md-12 mt-2">
                                    <h4 class="sub-title">Click The Create account Button</h4>
                                    <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}>Click the Create account button, fill in the details and select the check box with become an agent beside it.</p>
                                 </div>
                                <div class="col-md-12 mt-2">
                                    <h4 class="sub-title">Submit The Form</h4>
                                    <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}>Submit the form by clicking the Create account button. We'll contact you to verify your application </p>
                                 </div>
                                <div class="col-md-12 mt-2">
                                    <Link to="/Create-Account" className="btn btn-warn btn-md"> Create account</Link>
                                 </div>
                             </div>
                            </div>
                        </div>
                    </div>
                 </div>   
             </section>




             <Footer/>


    </div>

    );
  }

}