

import React, {useContext, useState, useEffect} from 'react';

import { format } from 'date-fns' 
import DateTimePicker from 'react-datetime-picker';
import {Context} from "../../Store";
import configData from "../../config.json";
import logo from '../../assets/img/logo2.png';
import Layout from './Layout';
 

const TransactionDetails = () => {
    const [state, setState] = useContext(Context);
    const [details, setDetails] = useState([]);
    const [disableBtn, setDisableBtn] = useState(false);
    const [loading, setLoading] = useState(true);

    const modal = () =>{
        window.$(".bs-example-modal-lg").modal("show") 
    }
    const back = () =>{
      window.location = window.location.origin + "/Transactions";
   }

    
  const transD = async () => {
    const transId = localStorage.getItem('transId');

    return fetch(`${configData.SERVER_URL}/orders/${transId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-api-token": window.localStorage.getItem("token")
      },
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson.data);
        setDetails(responseJson.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });

  }

  useEffect(() => {
    const interval = setInterval(() => {
      transD();
      }, 3000);
      return () => clearInterval(interval);
  }, []);
  


  
    return (

     <Layout>

        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
          <div class="modal-dialog bottom qrCode modal-lg">  
            <div class="modal-content">
                          <div class="row">
                                <div className="col-md-3"></div>
                                  <div className="col-md-6">
                                    <div class="row">

                                      <div className="col-12 mt-4">
                                          <h4 className="dText"  style={{fontWeight:'bold', marginTop:'9%', textAlign:'center'}}>Payment Method</h4>
                                            <h4 className="dTextTwo"  style={{fontWeight:'bold', float:'none', textAlign:'center'}}>{details.paymentMethod}</h4>

                                        </div>
                                        <div class="col-md-12 mt-2">
                                              
                                              <h4 className="dText"  style={{fontWeight:'bold', marginTop:'9%', textAlign:'center'}}>Delivery Code</h4>
                                              <h4 className="dTextTwo"  style={{fontWeight:'bold', float:'none', textAlign:'center'}}>{details.confirmationCode}</h4>
                                          </div>

                                      </div>
                                    </div>
                                <div className="col-md-3"></div>
                            </div>
            </div>
          </div>
        </div>
       
     </Layout>
    );

}

export default TransactionDetails;