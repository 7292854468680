import React, {useState, useEffect} from 'react';
import logo from '../../assets/img/logo2.png';
import configData from "../../config.json";

function EditProfile() {

    const [firstName, setfirstName] = useState(localStorage.getItem('firstName'));
    const [lastName, setlastName] = useState(localStorage.getItem('lastName'));
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [isDisable, setIsDisable] = useState(false);
    const [disablebtn, setDisableBtn] = useState(false);


    const updateDetails = () => {

         
        if (firstName=== '' || lastName === '' || email === '' ) {
                 alert('Field is Empty')
                 return;
          }
          setIsDisable(true);
              
            fetch(`${configData.SERVER_URL}/profile`, {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-auth-token": window.localStorage.getItem("token")
              },
              body: JSON.stringify({
                firstName:firstName,
                lastName:lastName,
                email:email,
              })
            })
              .then(response => {
                return response.json();
              })
              .then(responseJson => {
                console.log(responseJson);
                if(responseJson.status === "success") {
                    window.localStorage.setItem("firstName", responseJson.user.firstName);
                    window.localStorage.setItem("lastName", responseJson.user.lastName);
                    window.localStorage.setItem("email", responseJson.user.email);
                    alert(responseJson.message)
                }
   
                if (responseJson.status === 'error') {
                  console.log(responseJson.message);
                  alert(responseJson.message);
                  return
   
                };
              })
              .catch(error => console.log(error));
   
   
       }
   
    const updatePass = async() => {
            
             if (newPassword === '' || currentPassword === '' ) {
                     alert('Empty Field')
                   return;
             }
            setIsDisable(true);
        
           
            fetch(`${configData.SERVER_URL}/auth/change-password`, {
              method: "post",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "x-auth-token":  window.localStorage.getItem("token")
              },
              body: JSON.stringify({
                currentPassword:currentPassword,
                newPassword:newPassword
              })
            })
              .then(response => {
                return response.json();
              })
              .then(responseJson => {
   
                setIsDisable(false);
                if (responseJson.status === "success") {
                     alert(responseJson.message)
                }
   
                if (responseJson.status === 'error') {
                  // this.setState({ disableBtn: false });
                  alert(responseJson.message)
                  return
   
                };
              })
              .catch(
                error => console.log(error.message)
               //  {setDisableBtn(false);}
                );
   
   
       }
   
   
    useEffect(() => { 
        const token =  window.localStorage.getItem("token");
        if (!token){ 
            window.location = window.location.origin + "/";
            return
        }
      }, []);    
   

    const back = () =>{
        window.location = window.location.origin + "/#profile";
     }

    return (
        <>


 <section class="contain">
     <div className="row">
         <section className="box1">

         </section>


         <section className="box2">

         <div className='row mNew-2'>
                <div className="col-8">
                 <div className="row" style={{
                    width:'100%',
                    paddingLeft: '20px',
                    paddingTop: '1%',
                    height:'75%',
                    backgroundColor: '#ffd200',
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15}}>
                            <span onClick={()=> back()} id="back">
                            <i class="fa fa-arrow-left d-flex justify-content-center backIcon"></i> 
                            </span>
                            <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>Edit Profile
                            </p>
                    </div>
                    </div>
                    <div className="col-4">
                            <img src={logo} className="img-fluid rounded mx-auto d-block vIcon"/>
                     </div>
                </div>


    <div class="col-md-12"> 
     <div class="row">
         <div class="col-1"></div>
         
                <div class="col-10 mt-3">                                        
               
                    <form class="border border-light p-4" style={{backgroundColor:"#fff"}}>

                        <p class="mb-4">Update Details</p>  

                        <div class="form-group">
                            <input type="text"  class="form-control" placeholder="First Name" name="firstName"   value={firstName} onChange={(e) => setfirstName(e.target.value)}/>
                        </div>  
                        <div class="form-group">
                            <input type="text"  class="form-control" placeholder="Last Name" name="lastName"   value={lastName} onChange={(e) => setlastName(e.target.value)}/>
                        </div>  
                        <div class="form-group">
                            <input type="text" class="form-control mb-4" placeholder="Email" name="email"  value={email} onChange={(e) => setEmail(e.target.value)}/>
                        </div>
                            
                            <div class="form-group">
                                    <button onClick={()=> updateDetails()} type="submit" class="btn btn-warn btn-md my-4 btn-block waves-effect waves-light">
                                          {disablebtn ? (<>Please wait</>) : (<>Update Details</>)}
                                    </button>
                            </div>
                    </form>
                </div>
         <div class="col-1"></div>

         <div class="col-1"></div>
                
                <div class="col-10 mt-5">                                        
               
                    <form enctype="multipart/form-data" id="submit" class="border border-light p-4" style={{backgroundColor:"#fff"}}>

                        <p class="mb-4">Update Password</p>    
                        <div class="form-group">
                            <input type="password" id="FormPassword" class="form-control" placeholder="Old Password" name="password"   value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)}/>
                        </div>   
                        <div class="form-group">
                            <input type="password" id="FormPassword" class="form-control" placeholder="New Password" name="password"   value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                        </div>
                            
                            <div class="form-group">
                                    <button onClick={()=> updatePass()} type="submit" class="btn btn-warn btn-md my-4 btn-block waves-effect waves-light" >
                                          {disablebtn ? (<>Please wait</>) : (<>Change Password</>)}
                                    </button>
                                    
                            </div>


                    </form>
                </div>
                       
         <div class="col-md-1"></div>
         </div> 
     </div>


            
            

         </section>


         <section className="box1">

         </section>
     </div>
 </section>
 

            
        </>
    );
}

export default EditProfile;