import React from 'react';
import Product from "../component/Product";
import energie from '../../../assets/img/energie.png';
 

function HomeTab() {

    const firstName =  window.localStorage.getItem("firstName");
      

    return (
        <div>

         <div className="top">
            <img src={energie} class="img-fluid rounded mx-auto d-block header-img"/>

            <div style={{top: '25%', position:'absolute'}}>
                <span style={{marginLeft: 50, color: "#000", fontSize: '15px'}}>Hey,</span>
                <p style={{marginLeft: 50, fontSize: '20px', color: "#000", fontWeight:'400'}}>{firstName}</p>
            </div>
         </div>


     <div className="buttonView">
            <div className="buttonViewTwo">
                <div className="products scrollmenu">
                   <Product/>
                </div>

                <div className="transactions">
                   <p style={{color: '#000', marginLeft: 30, fontSize: '15px', marginHorizontal: '10%',}}>
                        Other Features
                    </p>
                    <div className="featParent">
                        <a href='/Logistics' className="featBox">
                            <p>Haulage/logistics</p>
                        </a>
                        <a href='/Oil-Deal' className="featBoxTwo">
                            <p>Oil & Gas Deals</p>
                        </a>
                     </div>
                 </div>
            </div>
            
        </div>
            
        </div>
    );
}

export default HomeTab;