import React, {Component} from 'react';
import Header from './header';
import Footer from './Footer';
import phonePic from '../assets/img/phn.png';
import gicon from '../assets/img/gicon.png';
import explainer from '../assets/video/vid1.mp4';
import videoImage from '../assets/img/videoImage.jpg';
import fuel from '../assets/img/Asset4.png';
import kerosine from '../assets/img/Asset5.png';
import diesel from '../assets/img/Asset1.png';
import engineOil from '../assets/img/Asset2.png';
import ph from '../assets/img/ph.png';
import pipe from '../assets/img/pipe.png';

export class Home extends Component {

    
  render() {
    return (


        <div> 

        <Header/>
            
                <div className="bckimg" id="home">
                    <div className="row">
                        <div className="col-md-7 mb-5">
                             <div className="center">
                                 <span className="box">Energie, saving time, journey and respect for you.</span>
                                 <h2 className="animated fadeIn mb-4 mt-4" id="h2">Get Petroleum Products at your Door step</h2>
                                 <p style={{color:"#ededed"}}>We are completing the supply  chain of the petroleum  industry from  exploration -production -manufacturing -shipping -storage -filling stations -energy -homes-(end users)</p>
                                 <a href="#" className="btn-mr  btn-iconprimary">
                                      <span className="icon-element">
                                        <i className="icofont icofont-brand-apple"></i>
                                        App Store
                                      </span>
                                    </a>
                                 <a href="https://play.google.com/store/apps/details?id=com.energie" className="btn-mr btn-iconsecondary">
                                      <span className="icon-element">
                                        <img src={gicon} alt="" style={{width:"15px"}}/>
                                        Play Store
                                      </span>
                                    </a>
                              </div>
                        </div>
                        <div className="col-md-5">
                            <div className="skl-mbl-img" id="hide">
                              <img src={phonePic} className="img-fluid rounded mx-auto d-block" id="img" alt=""/>
                                </div>
                        </div>
                    </div>
                    
                 </div>

             
            
         <div className="container-fluid" id="about">
                  <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-5">
                              <h4><span className="pull-left"> ABOUT ENERGIE</span></h4>
                                <div className="border2" style={{marginTop:"5px"}}></div>
                                <div className="" style={{float: "left", clear: "both"}}>
                                    
                                    <video  loop autoPlay muted width="100%" height="100%" poster={videoImage} controls="">
                                       <source src={explainer} type="video/mp4"/>
                                    </video>
                                    </div>
                                </div>

                            <div className="col-md-7 abt-m"> 
                                <strong className="pull-right  wow fadeInUp animated" data-wow-delay="0.2s" id="welcome"> Energie is a mobile application that enables more coverage and easy access of petroleum products to the consumers/customers.</strong>
                                <p className=" wow fadeInUp animated p2" data-wow-delay="1s">  For the first time in Africa, customers will have the upper hand and purchase petroleum products/derivatives at their convenience, saving time for them and journey</p>
                                <p className=" wow fadeInUp animated p2" data-wow-delay="1s">We are a non-traditional  diversity  tech  company  with  emphasis  on social  responsibility.</p>
                                <p className=" wow fadeInUp animated p2" data-wow-delay="1s">  Our objective is to use technology  to reach out to the community in which  we operate  and aid them in  achieving  sustainable  development.</p>
                                <p className=" wow fadeInUp animated p2" data-wow-delay="1s">We have adopted United Nations Educational, Scientific and Cultural Organization(UNESCO) Sustainable Development Goals(SDG) as our vision  standards. Energie Will  be able to contribute  to the communities  it operates in to achieve (no poverty,  zero hunger, good health  and well- being,  Quality  education ,gender equality,  reduce inequality,  sustainable  cities and communities,  industry innovation  and infrastructure,  affordable and clean energy  decedent work and economic growth, climate action) among others.</p>
                            </div>
                         </div>
                   </div>
              </div>


                  <span className="float-btn">
                      <a href="/HomeApp" className="btn btn-floating btn-warn">
                            <img src={pipe} class="img-fluid rounded mx-auto d-block" id="icon" width="60%"/>
                       </a>
                   </span>

			 <section  id="products">
			   <div className="container">
				  <div className="row">
				   <div className="col-sz-6 text-center wow fadeIn mt-2" data-wow-delay="0.2s">
						<img src={fuel} width="50px"/>
						<h6 style={{marginTop:'5px'}}>FUEL</h6>
					</div>
					<div className="col-sz-6 text-center wow fadeIn mt-2" data-wow-delay="0.2s">
						<img src={kerosine} width="40px"/>
						<h6 style={{marginTop:'5px'}}>KEROSINE</h6>
					</div>
					<div className="col-sz-6 text-center wow fadeIn mt-2" data-wow-delay="0.2s">
						<img src={diesel} width="40px"/>
						<h6 style={{marginTop:'5px'}}>DIESEL</h6>
					</div>
					<div className="col-sz-6 text-center wow fadeIn mt-2" data-wow-delay="0.4s">
						<img src={engineOil} width="50px"/>
						<h6 style={{marginTop:'5px'}}>ENGINE OIL</h6>
					</div>
				   </div> <br/>
				</div>        
			</section>  



           <section className="container-fluid" id="works">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-7">
                            <div className="sec-titile-wrapper">
                              <h4 className="section-title">HOW ENERGIE WORKS</h4>
                              <div className="border2" style={{margin: "0 0 25px"}}></div>
                               
                            <div className="row"  style={{float:'left', clear: 'both'}}>
                                <div class="col-md-12 mt-2">
                                    <h4 class="sub-title">Download the App</h4>
                                    <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:'15px', color:'#4A4A4A'}}>Search for energie on your Play Store/App Store, download it or click Buy Product on our website(www.energieng.com)</p>
                                 </div>
                                <div class="col-md-12 mt-2">
                                    <h4 class="sub-title">Create Account/ Login</h4>
                                    <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:'#4A4A4A'}}>Create your account and log-in </p>
                                 </div>
                                <div class="col-md-12 mt-2">
                                    <h4 class="sub-title">Order Product &amp; Make payment</h4>
                                    <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fonSize:"15px", color:"#4A4A4A"}}>Click the make order button, select the product(Fuel, Diesel, Kerosine, Engine Oil, Cooking Gas) you want to purchase and make payment. Go to your profile area to track your order.</p>
                                 </div>
                                <div class="col-md-12 mt-2">
                                    <h4 class="sub-title">Recieve Your Product at your Door step</h4>
                                    <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:'15px', color:'#4A4A4A'}}>Once your order is sucessfull you will receive your product at your door step.</p>
                                 </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="skl-mbl-img-2">
                              <img src={ph} className="img-fluid rounded mx-auto d-block" width="80%" alt=""/>
                            </div>
                        </div>
                    </div>
                 </div>   
             </section>


             <Footer/>



    </div>

    );
  }

}