

import React, {useContext, useState, useEffect} from 'react';
import { usePaystackPayment  } from 'react-paystack';
import { useHistory } from 'react-router-dom';

import { format } from 'date-fns' 
import DateTimePicker from 'react-datetime-picker';
import {Context} from "../../Store";
import configData from "../../config.json";
import logo from '../../assets/img/logo2.png';
import Layout from './Layout';
 
const {REACT_APP_PAYMENT_API_URL} = process.env;

const OrderSummary = () => {
  const history = useHistory();
    const [state, setState] = useContext(Context);
    const [amount, setAmount] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [disableBtn, setDisableBtn] = useState(false);
    const [isPayWithCard, setisPayWithCard] = useState(false);
    const [reference, setReference] = useState();
    const [orderId, setOrderId] = useState();
    const [order, setOrder] = useState([]);
    const [shouldDispatch, setshouldDispatch] = useState();
    const [transaction, setTransaction] = useState([]);
    const [serviceCharge, setserviceCharge] = useState(0);
    const [loading, setLoading] = useState(true);
    const [deliveryText, setDeliveryText] = useState(false);
  
    const back = () => {
      window.location = window.location.origin + "/MyLocation";
   }
  
    const litres =  window.localStorage.getItem("litres");
    const productId =  window.localStorage.getItem("productId");
    const productSelected =  window.localStorage.getItem("productSelected");
    const pricePerLitre =  window.localStorage.getItem("pricePerLitre");
    const productUnit =  window.localStorage.getItem("productUnit");
    const address =  window.localStorage.getItem("address");
    const deliveryTime =  window.localStorage.getItem("deliveryTime");
    const lat =  window.localStorage.getItem("lat");
    const lng =  window.localStorage.getItem("long");
    const destination = [lng, lat];


       
  useEffect(() => { 
    const token =  window.localStorage.getItem("token");
    if (!token || !address){ 
        window.location = window.location.origin + "/";
        return
    }
  }, []);    


  const getCharge = async () => {
    setLoading(true)

    
      return fetch(`${configData.SERVER_URL}/settings/service-charge`, {
        method: "get",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "x-api-token": window.localStorage.getItem("token")
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === "success") {
            console.log(responseJson);

               if(productUnit === 'Litre'){
                  if(litres <= 30){
                      setserviceCharge(responseJson.data.serviceCharge);
                      const servCharg = responseJson.data.serviceCharge;
                      window.localStorage.setItem('serviceCharge', servCharg);
                      const totalA = (litres * pricePerLitre) + Number(servCharg);
                      setTotalAmount(totalA);
                      setLoading(false);
                      return
                    }
                  if(litres > 30 && litres <= 200){
                      setserviceCharge(responseJson.data.serviceChargeAbove30Litres);
                      const servCharg = responseJson.data.serviceChargeAbove30Litres;
                      window.localStorage.setItem('serviceCharge', servCharg);
                      const totalA = (litres * pricePerLitre) + Number(servCharg);
                      setTotalAmount(totalA);
                      setLoading(false);
                      return
                    }
                  if(litres > 200){
                      setserviceCharge(0);
                      window.localStorage.setItem('serviceCharge', 0);
                      setDeliveryText(true);
                      setLoading(false);
                      return
                    }
                }


              if(productUnit === 'KG'){
                if(litres <= 13){
                    setserviceCharge(responseJson.data.serviceCharge);
                    const servCharg = responseJson.data.serviceCharge;
                    window.localStorage.setItem('serviceCharge', servCharg);
                    const totalA = (litres * pricePerLitre) + Number(servCharg);
                    setTotalAmount(totalA);
                    setLoading(false);
                    return
                  }
                if(litres > 13 && litres <= 50){
                    setserviceCharge(responseJson.data.serviceChargeAbove30Litres);
                    const servCharg = responseJson.data.serviceChargeAbove30Litres;
                    window.localStorage.setItem('serviceCharge', servCharg);
                    const totalA = (litres * pricePerLitre) + Number(servCharg);
                    setTotalAmount(totalA);
                    setLoading(false);
                    return
                  }
                  if(litres > 50){
                      setserviceCharge(0);
                      window.localStorage.setItem('serviceCharge', 0);
                      setDeliveryText(true);
                      setLoading(false);
                      return
                    }
                
            }
                  
          }
        })
        .catch((error) => {
          console.error(error);
        });
  }

  
   
   const placeOrder = async (paymentMethod) => {

       setDisableBtn(true);
       try {

           return fetch(`${configData.SERVER_URL}/orders`, {
             method: "post",
             headers: {
               Accept: "application/json",
               "Content-type": "application/json",
               "x-api-token": window.localStorage.getItem("token"),
             },
               body: JSON.stringify({       
                   productId: productId,
                   quantity: Number(litres),
                   address: address,
                   deliveryTime: deliveryTime,
                   destination: destination,
                   paymentMethod: paymentMethod
               })
           })
           .then((response) => response.json())
           .then((responseJson) => {

               if(responseJson.status === "error"){
                   console.log(responseJson.message);
               }

             if (responseJson.status === "success") {
                 console.log(responseJson.data)
                 setState(previousState => ({
                   ...previousState,
                   order: responseJson.data,
                   transaction: responseJson.data.transaction
                 }))
                //  const order = responseJson.data;
                //  const transaction = responseJson.data.transaction;
                 setOrder(responseJson.data);
                 setTransaction(responseJson.data.transaction);
                 setReference(responseJson.data.transaction.reference);
                 setshouldDispatch(responseJson.data.shouldDispatch);
                 const orderId = responseJson.data.transaction.orderId;
                 setOrderId(responseJson.data.transaction.orderId);
                
                

                //  localStorage.setItem("transaction", transaction);
                //  localStorage.setItem("reference", transaction.reference)

               if (paymentMethod === "online") {
                    setDisableBtn(false);
                    setisPayWithCard(true);
               }

               if (paymentMethod === "on_delivery") {
                    setDisableBtn(false);
                    if (responseJson.data.shouldDispatch) {
                        localStorage.setItem("orderId", orderId);
                        window.localStorage.setItem("transId", responseJson.data._id);
                         
                          window.$(".bs-example-modal-lg").modal("hide") 
                          history.push('/TransactionDetails');  
                    } else {
                      alert('Your Order has been recieved, we will deliver to you at your scheduled time');
                      localStorage.setItem("orderId", orderId);
                      window.localStorage.setItem("transId", responseJson.data._id);
                   
                      window.$(".bs-example-modal-lg").modal("hide") 
                        history.push('/TransactionDetails'); 
                    }
               }
             }


           })
           .catch((error) => {
               console.log(error);
           });
           
               } catch (e) {
         console.error(e)
       }
     }

  //  const calculate = () => {
  //      const A = (litres * pricePerLitre);
  //      setAmount(A);
  //      const servCharg = window.localStorage.getItem('serviceCharge');
  //      const totalA = (litres * pricePerLitre) + Number(servCharg);
  //      setTotalAmount(totalA);
  //      window.localStorage.setItem('totalAmount', totalA);
  //    }
 
    useEffect(() => {
      getCharge();
    }, [])


  //  const reference = window.localStorage.getItem("reference");
   const email = window.localStorage.getItem("email");


  const config = {
      reference: reference,
      email: email,
      amount: totalAmount * 100,
      publicKey: REACT_APP_PAYMENT_API_URL,
  };
  
  
  const onSuccess = (reference) => {
      
    console.log(reference);

  //   localStorage.getItem("order");
  //   localStorage.getItem("transaction");

      return fetch(`${configData.SERVER_URL}/transactions/verify`, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
          "x-api-token": window.localStorage.getItem("token"),
        },
          body: JSON.stringify({       
            reference: reference
          })
      })
      .then((response) => response.json())
      .then((responseJson) => {
          console.log(responseJson)

        if(responseJson.status === "error"){
              console.log(responseJson.message);
          }

        if (responseJson.status === "success") {
            if(litres > 30){
              setDisableBtn(false);
              alert('Thanks Transaction Successful. Your order cannot be delivered by our bike agents, we will manually send it to you');
              window.localStorage.setItem("transId", responseJson.data._id);
           
                window.$(".bs-example-modal-lg").modal("hide") 
                history.push('/TransactionDetails');  
            }else{
            if (shouldDispatch) {
                  setDisableBtn(false);
                  window.localStorage.setItem("transId", responseJson.data._id);
               
                  window.$(".bs-example-modal-lg").modal("hide") 
                    history.push('/TransactionDetails'); 
                } else {
                setDisableBtn(false);
                // navigate somewhere else 
                alert('Thank Your Transaction Successful. We will deliver to your location at your scheduled time');
                window.localStorage.setItem("transId", responseJson.data._id);
             
                window.$(".bs-example-modal-lg").modal("hide") 
                  history.push('/TransactionDetails'); 
            }
            }
        }

      })
      .catch((error) => {
          console.log(error);
      });



  };

  
  const onClose = () => {
    
    console.log('closed')
  }

  const PaystackHookExample = () => {
      const initializePayment = usePaystackPayment(config);
      return (
        <div>
            <button class="btn btn-warn my-4 btn-block waves-effect waves-light" onClick={() => {
                initializePayment(onSuccess, onClose)
            }}>Pay Now</button>
        </div>
      );
  };


    return (

     <Layout>
      
         <div>
            {loading ? <> <span>loading</span>  </> : <> 
              <div className='row mNew-2'>
                <div className="col-8">
                 <div className="row" style={{
                    width:'100%',
                    paddingLeft: '20px',
                    paddingTop: '1%',
                    height:'75%',
                    backgroundColor: '#ffd200',
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15}}>
                            <span onClick={()=> back()} id="back">
                            <i class="fa fa-arrow-left d-flex justify-content-center backIcon"></i> 
                            </span>
                            <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>Order Summary
                            </p>
                    </div>
                    </div>
                    <div className="col-4">
                            <img src={logo} className="img-fluid rounded mx-auto d-block vIcon"/>
                     </div>
                </div>

                <div className="container">

                  <div className="row">
           
                  <div class="col-md-12">
                        <div class="row">
                            <div className="col-md-2"></div>
                            <div className="col-md-8 card">

                                    <div className="row">
                                        
                                        <div class="col-md-12 mt-2">
                                            <span class="text-center font-weight-bold">PRODUCT:</span>
                                            <span class="p" style={{float:"right"}}>{productSelected}</span>
                                            <hr/>
                                        </div>
                                        
                                        <div class="col-md-12 mt-2">
                                            <span class="text-center font-weight-bold">DELIVERY TIME:</span>
                                            <span class="p" style={{float:"right"}}>{format(new Date(deliveryTime), 'MMMM, do, yyy')}</span>
                                            <hr/>
                                        </div>
                                        
                                        <div class="col-md-12 mt-2">
                                            <span class="text-center font-weight-bold">DELIVERY ADDRESS:</span>
                                            <span class="p" style={{float:"right"}}>{address}</span>
                                            <hr/>
                                        </div>
                                        
                                        <div class="col-md-12 mt-2">
                                            <span class="text-center font-weight-bold">QUANTITY:</span>
                                            <span class="p" style={{float:"right"}}>{litres} {productUnit}</span>
                                            <hr/>
                                        </div>
                                        
                                        <div class="col-md-12 mt-2">
                                            <span class="text-center font-weight-bold">PRICE PER {productUnit}:</span>
                                            <span class="p" style={{float:"right"}}># {pricePerLitre}</span>
                                            <hr/>
                                        </div>
                                        
                                        <div class="col-md-12 mt-2">
                {deliveryText ?
                    <span class="text-center font-weight-bold">We'll contact you on delivery charge.</span>
                      : <>
                    <span class="text-center font-weight-bold">DELIVERY FEE:</span>
                    <span class="p" style={{float:"right"}}># {serviceCharge}</span>
                       <hr/>
                </> }                   
                                        </div>
                                        
                                        <div class="col-md-12 mt-2">
                                            <span class="text-center font-weight-bold">Total Amount:</span>
                                            <span class="p" style={{float:"right"}}># {totalAmount}</span>
                                            
                                        </div>


                                        <div class="col-md-12 mt-2">
                                            <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" data-toggle="modal" data-target=".bs-example-modal-lg">PAY NOW</button>
                                        </div>

        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
          <div class="modal-dialog bottom modal-lg">  
            <div class="modal-content">
              <div class="modal-header">
                <p class="title" id="myModalLabel">Payment Method</p>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              </div>
                            <div class="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8 ml-lg-3">
                                        <div class="col-md-12 mt-2">
                                          {isPayWithCard ? <>
                                            <PaystackHookExample /> 
                                          </> : <>
                                            <button type="submit" class="btn btn-warn my-4 btn-block waves-effect waves-light" onClick={()=> placeOrder('online')} disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>PAY WITH CARD</>)}</button>

                                            <button type="submit" class="btn btn-white my-4 btn-block waves-effect waves-light" onClick={()=> placeOrder("on_delivery")} disabled={disableBtn}>{disableBtn ? (<>Please wait</>) : (<>PAY ON DELIVERY</>)}</button>
                                          </>}
                                        </div>
                              </div>
                                <div className="col-md-2"></div>
                            </div>
              </div>
            </div>
          </div>

                      </div>                                                       
                                
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>


                  </div>
                    
                </div>
              </>}
          </div>

      </Layout>
    );

}

export default OrderSummary;