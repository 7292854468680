

import React, {useContext, useState, useEffect} from 'react';

import { format } from 'date-fns' 
import DateTimePicker from 'react-datetime-picker';
import {Context} from "../../Store";
import configData from "../../config.json";
import logo from '../../assets/img/logo2.png';

import Layout from './Layout';
 

const Transactions = () => {
    const [state, setState] = useContext(Context);
    const [amount, setAmount] = useState();
    const [totalAmount, setTotalAmount] = useState();
    const [disableBtn, setDisableBtn] = useState(false);
    const [loading, setLoading] = useState(true);
    const [trans, setTrans] = useState([]);
  
    const getTrans = async () => {
      
      return fetch(`${configData.SERVER_URL}/orders`, {
        method: 'get',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-api-token': window.localStorage.getItem("token"),
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.status === 'success') {
            setTrans(responseJson.data);
            setLoading(false)
          }
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
    useEffect(() => {
      getTrans();
      const interval = setInterval(() => {
        getTrans();
      }, 5000);
  
      return () => clearInterval(interval);
    }, []);
 
    const back = () =>{
        window.location = window.location.origin + "/#profile";
     }

     const transDetails = (id) => {
     
        window.localStorage.setItem("transId", id);
        
        window.location = window.location.origin + "/TransactionDetails";
    
      }


    return (

     <Layout>
         <div>

         <div className='row mNew-2'>
                <div className="col-8">
                 <div className="row" style={{
                    width:'100%',
                    paddingLeft: '20px',
                    paddingTop: '1%',
                    height:'75%',
                    backgroundColor: '#ffd200',
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15}}>
                            <span onClick={()=> back()} id="back">
                            <i class="fa fa-arrow-left d-flex justify-content-center backIcon"></i> 
                            </span>
                            <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>Transactions
                            </p>
                    </div>
                    </div>
                    <div className="col-4">
                            <img src={logo} className="img-fluid rounded mx-auto d-block vIcon"/>
                     </div>
                </div>
            
                <div className="container">

                  <div className="row">
           
   
       {loading ? (<> 
         <div className="col-12" style={{marginTop:'50%'}}>
             <div class="d-flex justify-content-center">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div> 
                </div>
            </div>
                    </>) : (<>               

                    {trans && trans.length > 0
                    ? trans.map(s => {
                        return <>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-1"></div>
                                        <div className="order col-10 mb-3" onClick={()=> transDetails(s._id)}>
                                            <div className="row">
                                                <div className="col-2">
                                                <img src={`${configData.SERVER_URL}/images/products/${s.product.image}`} className="img-fluid mx-auto d-block orderImage" />
                                                </div>
                                                <div className="orderProduct col-5">
                                                <p className="orderText">{s.product.name}</p>
                                                <p className="orderTextTwo"> # {s.totalAmount}</p>
                                                </div>
                                                <div className="orderDate col-5">
                                                <p className="orderText">
                                                    {format(new Date(s.deliveryTime), 'MMMM')}
                                                </p>
                                                <p className="orderText">
                                                    {format(new Date(s.deliveryTime), 'do')} / {format(new Date(s.deliveryTime), 'yyy')}
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                    <div className="col-1"></div>
                                    </div>
                                </div>
                        </> })
                        : <div style={{height:"100%", width:'100%', marginLeft:'30%', marginTop:'20%', justifyContent:'center', alignItems:'center'}}>
                                <p style={{color:'#000'}}>No Transaction Found</p>
                        </div>}

         </>)}

                  </div>
                    
                </div>

          </div>
     </Layout>
    );

}

export default Transactions;