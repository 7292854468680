import React, {Component} from 'react';
import Header from './header';
import Footer from './Footer';
import image from '../assets/img/image.png';

export class SaleOnEnergie extends Component {

    
  render() {
    return (


    <div style={{backgroundColor:"#fff"}}> 

        <Header/>

        <div class="col-md-12"> 
         <div class="row">
             <div class="col-md-3">               
             </div>
             <div class="col-md-6 mt-5 mb-5">
                                     <div class="border border-light p-5">

                                            <p class="h4 mb-4">Do you what to sell products on Energie? then fill this form</p>
                                         

                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="text" id="defaultRegisterFormFullName" class="form-control" placeholder="Full Name" name="fullname"/>
                                                </div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="text" id="FormLocation" class="form-control" placeholder="Location" name="location"/>
                                                </div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="text" id="FormQuantity" class="form-control" placeholder="Product" name="product"/>
                                                </div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="text" id="FormQuantity" class="form-control" placeholder="Quantity" name="quantity"/>
                                                </div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="tel" id="FormPhoneNumber" class="form-control" placeholder="Phone Number" name="phoneNumber"/>
                                                </div>
                                            </div>

                                            <div class="form-row m-1">
                                                <div class="col-12">
                                                    <input type="email" id="FormEmail" class="form-control" placeholder="Email" name="email"/>
                                                </div>
                                            </div>

                                         
                                            <button class="btn btn-warn my-4 btn-block waves-effect waves-light" type="submit" name="add">SUBMIT</button>
                                        
                                        </div>
                                    

             </div>
             <div class="col-md-3">               
             </div>
          </div> 
         </div>



         <Footer/>


        </div>




    );
  }

}