import React, {useContext, useState, useEffect} from 'react';

import Layout  from './Layout';
import HomeTab from "./tabView/HomeTab"
import ProfileTab from "./tabView/ProfileTab";
import pipe from '../../assets/img/pipe.png';
import Product from "./component/Product";
import Login from "./component/Login";
 
function HomeApp() {


    const [home, setHome] = useState(true);
    const [market, setMarket] = useState(false);
    const [basket, setBasket] = useState(false);
    const [profile, setProfile] = useState(false);
    const [activeBtn, setActiveBtn] = useState('');


    const homeFunc = () => {
        setHome(true);
        setProfile(false);
        window.location = window.location.origin + "/";
    }
    const orderFunc = () => {
        window.$(".bs-example-modal-lg").modal("show") 
    }
    const ProfileFunc = () => {     
        const userId = localStorage.getItem('token');
    
        if(!userId){
             window.$(".login-modal").modal("show") 
             return
        }
        setProfile(true);
        setHome(false);
        window.location = window.location.origin + "/#profile";
    }

    useEffect(() => { 
        const token =  window.localStorage.getItem("token");
        if (!token){ 
            window.$(".login-modal").modal("show") 
            return
        }
        const url =   window.location.href;
    

        if(url === "https://energieng.com/#profile"){
            setProfile(true);
            setHome(false);
        }

    
      }, []);    

    return (
        <Layout>

 
         <div>

                {home && 

                    <div id="home">

                        <HomeTab/>

                     </div>
                   }

                {profile && 

                    <div id="profile">

                        <ProfileTab/>

                     </div>
                   }


          {/* Bottom tabs */}

        <div className="bottomTab border-top">
            <div className="row">
                        <div className="col-4" onClick={()=> homeFunc()}  style={{cursor:'pointer'}}>
                                  <i class="fa fa-home d-flex justify-content-center" style={(home)? {fontSize: '1.2rem', textAlign:'center', color:'#FFBC29'} : {fontSize: '1.2rem', textAlign:'center', color:'gray'}}></i>
                                <p style={{fontSize: 12, textAlign:'center', color:'gray', marginTop:2}}>Home</p>
                        </div>
                        <div className="col-4" onClick={()=> orderFunc()}  style={{cursor:'pointer'}}>
                              <span  id="btn-floating">
                                  <img src={pipe} class="img-fluid rounded mx-auto d-block" id="icon" width="60%"/>
                              </span>
                        </div>
                        <div className="col-4" onClick={()=> ProfileFunc()}  style={{cursor:'pointer'}}>
                                <i class="fa fa-user d-flex justify-content-center" style={(profile)? {fontSize: '1.2rem', textAlign:'center', color:'#FFBC29'} : {fontSize: '1.2rem', textAlign:'center', color:'gray'}}></i>
                                <p style={{fontSize: 12, textAlign:'center', color:'gray', marginTop:2}}>Profile</p>
                        </div>
              </div>
        </div>


         </div>





         <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
          <div class="modal-dialog bottom prod modal-lg">  
            <div class="modal-content">
                            <div class="row">
                                <div class="col-md-12 mt-2">
                        <h4 className="dText" style={{fontWeight: "bold", marginTop:'4%', marginLeft: '10%'}}>Select a Product</h4>
                                    <div className='modalProd scrollmenu'>
                                       <Product/>
                                       </div>
                                </div>
                            </div>
                </div>
              </div>
            </div>

 
 
 
      </Layout>
 
    );
}

export default HomeApp;