import React, {useState} from 'react';
import '../assets/css/font-awesome.css';
import '../assets/css/style2.css';
import {Link} from "react-router-dom";
import logo from '../assets/img/logo.png';
import BuyProduct from "./BuyProduct";

const Header = () => {

    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

      const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

      
      const token =  window.localStorage.getItem("token");

    return (
  <div>
                    <div class="nav-bottom"></div>
    
                    <div class="top-nav"> 
        
                        <div class="top-left" id="hide">
                            <ul class="nav">
                                <li class="nav-item">
                                    <a href="#" class="social1"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                            <li class="nav-item">
                                    <a href="#" class="social2"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                            <li class="nav-item">
                                    <a href="#" class="social2"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                            </ul>
                        </div>
            
                        <div class="top-right">
                           <ul class="nav">
                            <li class="nav-item">
                                    <a href="tel:+2348096137067" style={{color:"white", paddingRight:"8px", fontSize:"10px",  fontWeight: "500;"}}><i class="fa fa-phone" aria-hidden="true"></i> +234 809 613 7067 , +234 813 537 0139 </a>
                                </li>
                            <li class="nav-item" id="hide">
                                    <a href="mailto:info@energieng.com" style={{color:"white", paddingRight:"50px", fontSize:"10px",  fontWeight: "500"}}><i class="fa fa-instagram" aria-hidden="true"></i> info@energieng.com</a>
                                </li>
                            <li class="nav-item login">
                                {!token ? (<> <a href="/Home" style={{color:"#000", padding:"8px", fontSize:"12px",  fontWeight: "700"}}> Register/ Login</a> </>) 
                                : (<> <a href="/#profile" style={{color:"#000", padding:"8px", fontSize:"12px",  fontWeight: "700"}}>My Profile</a></>) }
                                    
                                </li>
                            </ul>
                       </div>
        
                     </div>
            
        
                 <nav class="navbar navbar-expand-md navbar-dark  scrolling-navbar">
                               <div class="container">
                                    <a href="/" class="navbar-brand">
                                        <img src={logo} width="50" alt="" id="imag"/>
                                    </a>
                                    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNav1" aria-controls="navbarNav1" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}>
                                        <span class="navbar-toggler-icon"></span>
                                    </button>
                                    <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav1">
                                        <ul class="navbar-nav ml-auto">
                                            <li class="nav-item">
                                                <a class="nav-link" href="/Home/#home">Home</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="/Home/#about">About Us</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="/Home/#products">Our Products</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link" href="/Home/#works">How It Works</a>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="/AgentReg" style={{color: "#FFBC29", fontWeight:"bold"}}>Become An Agent</Link>
                                            </li>
                                            <li class="nav-item">
                                                <Link class="nav-link" to="/Sale-On-Energie">Sale On Energie</Link>
                                            </li>
                                        </ul>
                                        <ul class="navbar-nav ml-auto">
                                            <li class="nav-item"> 
                                                <Link to="/" class="nav-link btn  btn-warn btn-sm">BUY PRODUCT</Link>
                                                
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </nav>

              

</div>         

    )
  

}

export default Header;