import React from 'react';
import Login from './component/Login';

const Layout = ({children}) => {
  
    return (<>

<section class="contain">
     <div className="row">
         <section className="box1">

         </section>  

         
         <section className="box2">    
         
                 {children}

         </section>
                 
          <section className="box1">

          </section>
       </div>
</section>


 {/* <Modal show={show} onHide={handleClose}>
        <Modal.Body> */}
             <Login/>
        {/* </Modal.Body>
   </Modal> */}
            
                </> );
};

export default Layout;