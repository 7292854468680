import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Header from './header';
import Footer from './Footer';
import deliverytwo from '../assets/img/DELIVERY.png';
import delivery from '../assets/img/energie.png';
import agent from '../assets/img/agent-reg.png';
import configData from "../config.json";

const AgentReg = () => {
    const [isloading, setIsloading] = useState(false)


    return (


    <div> 

        <Header/>


        
             
            
        <div class="container-fluid" id="agent-1">
                  <div class="container">




                        <div class="row align-items-center mt-3">

                            <div class="col-md-8 abt-m">
                                <div className="row">
                                <div className="col-md-12">
                                    <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="welcome">Dear Friend,
                                    </strong>
                                </div>
                                <div className="col-md-12">
                                <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}>
                                    I am reaching out to you because you have applied to be an Alpha Agent through our platforms or partners, I appreciate you.<br/>  
                                    Uwaomachukwu coy Ltd, also known as Alpha Uwc is a group of people coming together to cause a paradigm shift on our persons and communities accross Africa. We believe we can do what the westerners have done in making their societies the envy of other Nations across the world.<br/>  
                                    Our vision is to secure Africa to God's Will and Purpose and we have adopted UNESCO's 17 SDGs as our Vision standard. Our goal/aim is to see all these goals come alive in our communities.<br/>  
                                    You are invited to join us turn Nigeria, starting from Port Harcourt in Rivers State to a civilised city with top-notch service delivery/consciousness.
                                    I look forward to seeing you in our training programs designed for you to achieve all the goals mentioned above. <br/>  
                                    Regards <br/>
                                    <span style={{fontWeight:'bold'}}>Mr.Samuel N.B Ezindu(CEO).</span>
                                    </p>
                                </div>
                                </div>
                            </div>
                            
                            <div class="col-md-4">
                                <div class="" style={{float: "left", clear: "both"}}>
                                    <img src={delivery} class="img-fluid rounded mx-auto d-block" width="80%"/>
                                    </div>
                                </div>
                         </div>

                          
                        <div class="row align-items-center mt-5">

                            <div class="col-md-8 abt-m">
                                <div className="row">
                                <div className="col-md-12">
                                    <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="welcome">Our Coverage
                                    </strong>
                                </div>
                                <div className="col-md-12">
                                <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}>
                                Prospective agents should apply directly for the job of covering any of the area in our groups below. You must have perfect understanding of the areas/neighborhoods and be a part of the communities to be eligible<br/>
                                    <span>Contact us for more info:  +234 813 537 0139, +234 809 613 7067 (WhatsApp). Email: info@uwaomachukwucoy.com</span>
                                    </p>
                                </div>
                                </div>
                            </div>


                            <div class="col-md-12 abt-m">
                                <div className="row">

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                 <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group A</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>ABULOMA</li>
                                                    <li>AMADI-AMA</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group B</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>D-LINE</li>
                                                    <li>OROWORUKWO</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group C</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>AGIP</li>
                                                    <li>EAGLE ISLAND</li>
                                                    <li>MGBUOSIMINI</li>
                                                    <li>MGBUESILARU</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group D</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>ELEKAHIA</li>
                                                    <li>RUMUKALAGBOR</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group E</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>NKPOGU</li>
                                                    <li>OGBUNABALI</li>
                                                    <li>REBISI</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group F</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>OLD GRA</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group G</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>NEW GRA</li>
                                                    <li>ELIGBAM</li>
                                                    <li>RUMUADAOLU</li>
                                                    <li>OROAZI</li>
                                                    <li>RUMUOLA</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group H</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>TERE - AMA</li>
                                                    <li>OKURU - AMA</li>
                                                    <li>PETER ODILI</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group I</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>EGBELU</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group J</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>ELELENWO</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group K</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>ELIOZU</li>
                                                    <li>ELIGBOLO</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group L</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>ELIOPRANWO</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group M</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>MGBUOBA</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group N</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>OGIDINGBA</li>
                                                    <li>TRANS-AMADI</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group O</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>RUMUOKORO</li>
                                                    <li>RUMUODOMAYA</li>
                                                    <li>RUMUAGHAOLU</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group P</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>RUMUDARA</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group Q</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>RUMUEME</li>
                                                    <li>RUMUEPIRIKOM</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group R</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>RUMUIBEKWE</li>
                                                    <li>RUMUOKWURUSI</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group S</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>RUMUIGBO</li>
                                                    <li>RUMUOMOI</li>
                                                    <li>RUMUOKWUTA</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group T</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>WOJI</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group U</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>RUMUOMASI</li>
                                                    <li>RUMUOBIOKANI</li>
                                                    <li>RUMUOGBA</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group V:</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>IWOFE</li>
                                                    <li>ADA-GEORGE</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group W:</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>DIOBU</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <strong class="pull-left  wow fadeInUp animated" data-wow-delay="0.2s" id="group">Group X:</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <ul>
                                                    <li>TOWN</li>
                                                    <li>BOROKIRI</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>


                             </div>
                         </div>

                         </div>




                        <div class="row align-items-center mt-3">

                            <div class="col-md-4">
                                <div class="" style={{float: "left", clear: "both"}}>
                                    <img src={deliverytwo} class="img-fluid rounded mx-auto d-block" width="80%"/>
                                    </div>
                                </div>

                            <div class="col-md-8 abt-m">
                                <strong class="pull-right  wow fadeInUp animated" data-wow-delay="0.2s" id="welcome">Alpha Agents sign up requirements <br/> 
                                   All agents must include in their application form (PDF FORMAT) these information :</strong>
                                <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}>
                                    1. Names, phone number(s), emails, all social media handles. <br/>  
                                    2. full Picture without makeup/black shades or caps etc. <br/>   (Natural looks) ,<br/> 
                                    3. State of Origin , <br/> 
                                    4. Local government , <br/> 
                                    5. Home town/ village community/family name , <br/> 
                                    6. Residential address in PHC , <br/> 
                                    7. Utility bill with letter from the name on it accepting relationship with you , <br/> 
                                    8. Religious organization  (church) leader's letter of recommendation for you , <br/> 
                                    9. A brief story of you. We are not focused on your educational background to meet up to the job requirements but will still like to know relevant information about you. <br/> 
                                    10. Your ability to learn new ideas and your take on moral standards.  <br/> 
                                    11. Alpha Agents with vehicles should indicate with details of their vehicles.
                                    </p>
                                <p class=" wow fadeInUp animated" data-wow-delay="1s" style={{fontSize:"15px", color:"#4A4A4A"}}> </p>
                            </div>
                            
                         </div>

                   <div class="row align-items-center">
                         <div className="col-md-2"></div>
                         <div className="col-md-8">

                                    <Link to="/Create-Account" class="btn btn-warn my-4 btn-block waves-effect waves-light" type="submit" name="add">
                                    {isloading ? (<> Loading...</>) : (<> Click to Register</>)} 
                                    </Link>
                        
                         </div>
                         <div className="col-md-2"></div>

                    </div>




                   </div>
              </div>
    

             <Footer/>


    </div>

    );

}

export default AgentReg;