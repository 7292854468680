import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route, Redirect
} from "react-router-dom";

import Store from './Store';
import OrderSummary from "./main/app/OrderSummary";
import {Home} from './main/home';
import Login from './main/Login';
import CreateAccount from './main/Create-Account';
import {Agent} from './main/become-an-agent';
import {MyOrder} from './users/MyOrder';
import {SaleOnEnergie} from './main/SaleOnEnergie';
import AgentReg from "./main/Agent-Registration";
import HomeApp from "./main/app/HomeApp";
import MakeOrder from "./main/app/MakeOrder";
import MyLocation from "./main/app/MyLocation";
import DeleteAcc from "./main/app/DeleteAcc";
import Payment from "./main/app/Payment";
import Transactions from "./main/app/Transactions";
import TransactionDetails from "./main/app/TransactionDetails";
import SearchAgent from "./main/app/SearchAgent";
import EditProfile from "./main/app/EditProfile";
import DeliveryAgent from "./main/app/DeliveryAgent";
import About from "./main/app/About";
import FAQ from "./main/app/FAQ";
import Logistics from "./main/app/Features/Logistics";
import OilDeal from "./main/app/Features/OilDeal";

function App() {
  return (
    <Store>
    <Router>
       <Switch>
          <Route path="/Home" exact>
            <Home />
          </Route>
          <Route path="/Login">
            <Login />
          </Route>
          <Route path="/become-an-agent">
            <Agent />
          </Route>
          <Route path="/Sale-On-Energie">
            <SaleOnEnergie />
          </Route>
          <Route path="/Delete-Account">
            <DeleteAcc />
          </Route>
          <Route path="/Create-Account">
            <CreateAccount />
          </Route>
          <Route path="/MyOrder">
            <MyOrder />
          </Route>
          <Route path="/AgentReg">
             <AgentReg/>
          </Route>
          <Router path="/" exact>
            <HomeApp/>
          </Router>
          {/* {
              (window.localStorage.getItem("token")) ? 
              <> */}
              <Route path="/MakeOrder">
                 <MakeOrder/>
              </Route>
              <Route path="/MyLocation">
                 <MyLocation/>
              </Route>
              <Route path="/OrderSummary">
                 <OrderSummary/>
              </Route>
              <Router path="/Payment">
                <Payment/>
              </Router>
              <Router path="/SearchAgent">
                <SearchAgent/>
              </Router>
              <Router path="/EditProfile" exact>
                <EditProfile/>
              </Router>
              <Router path="/DeliveryAgent" exact>
                <DeliveryAgent/>
              </Router>
              <Route path="/Transactions">
                <Transactions/>
              </Route>
              <Route path="/TransactionDetails">
                <TransactionDetails/>
              </Route>
              <Route path="/About">
                <About/>
              </Route>
              <Route path="/FAQ">
                <FAQ/>
              </Route>
              <Router path="/Logistics" exact>
                <Logistics/>
              </Router>
              <Router path="/Oil-Deal" exact>
                <OilDeal/>
              </Router>
              {/* </> : <Redirect to="/HomeApp" />
          } */}
        </Switch>
    </Router>
    </Store>
  );
}

export default App;
