import React, {useContext, useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from "react-hook-form";
import DateTimePicker from 'react-datetime-picker';
import {Context} from "../../Store";
import configData from "../../config.json";
import logo from '../../assets/img/logo2.png';
import Layout from './Layout';



function MakeOrder() {
  const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [state, setState] = useContext(Context);
    const [prod, setProd] = useState([]);
    const [login, setLogin] = useState(false);
    const [time, setTime] = useState(new Date());
    const [disablebtn, setDisablebtn] = useState(false);
    const [message, setMessage] = useState(false);
    const [isLoading, setisLoading] = useState(false);


    const getProducts = async () => {
        return fetch(`${configData.SERVER_URL}/products`, {
          method: "get",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "x-api-token": window.localStorage.getItem("token"),
          },
        })
          .then((response) => response.json())
          .then((responseJson) => {
            if (responseJson.status === "success") {
              // console.log(responseJson)
              setProd(responseJson.data);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    
      useEffect(() => { 
        const interval = setInterval(() => {
          getProducts();
          }, 3000);
          return () => clearInterval(interval);
    
      }, []);
  
      const next = (data) => {
        window.localStorage.setItem("litres", data.litres);
        window.localStorage.setItem("deliveryTime", time);
        
        setState(previousState => ({
          ...previousState,
           litres: data.litres,
           deliveryTime: time,
        }));


        if (navigator.geolocation) {
          navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
              if (result.state === "granted") {
                  console.log(result.state);
              
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(success);

                function success(pos) {
                    var crd = pos.coords;
                    console.log(crd + ' inside');
                    const address = crd.address;
                    const lat = crd.latitude;
                    const long = crd.longitude;
                
                    console.log(pos.coords);
                    localStorage.setItem("address", address);
                    localStorage.setItem("lat", lat);
                    localStorage.setItem("long", long);
                    setisLoading(false);
                }



              } else if (result.state === "prompt") {
                navigator.geolocation.getCurrentPosition(success, error, options);
              } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                alert('Please turn on your location to use this service');
              }
              result.onchange = function () {
                console.log(result.state);
              };
            });
        } else {
          alert("Sorry Not available!");
        }


        
        history.push('/MyLocation');
        // window.location = window.location.origin + "/MyLocation ";
       }

       const back = () =>{
           window.location = window.location.origin + "/";
        }


    const productSelectedImage = localStorage.getItem('productSelectedImage');
    const productSelected = localStorage.getItem('productSelected');
    const pricePerLitre = localStorage.getItem('pricePerLitre');
    const productUnit = localStorage.getItem('productUnit');


       
    useEffect(() => { 
      const token =  window.localStorage.getItem("token");
      if (!token || !productSelected){ 
          window.location = window.location.origin + "/";
          return
      }
    }, []);    
 


    var options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };

  function success(pos) {
    var crd = pos.coords;
    // console.log(crd + ' ghgj');
    const address = crd.address;
    const lat = crd.latitude;
    const long = crd.longitude;

    // console.log(pos.coords);
    localStorage.setItem("address", address);
    localStorage.setItem("lat", lat);
    localStorage.setItem("long", long);
    setisLoading(false);
  }
  
  function error(err) {
    // console.warn(`ERROR(${err.code}): ${err.message}`);
  }

  const getGeolation = ()=> {
      if (navigator.geolocation) {
        navigator.permissions
          .query({ name: "geolocation" })
          .then(function (result) {
            if (result.state === "granted") {
                // console.log(result.state);
            
              //If granted then you can directly call your function here
              navigator.geolocation.getCurrentPosition(success);
            } else if (result.state === "prompt") {
              navigator.geolocation.getCurrentPosition(success, error, options);
            } else if (result.state === "denied") {
              //If denied then you have to show instructions to enable location
            }
            result.onchange = function () {
              // console.log(result.state);
            };
          });
      } else {
        alert("Sorry Not available!");
      }
  }

  useEffect(()=> {
    const interval = setInterval(() => {
      getGeolation();
    }, 1000);

    return () => clearInterval(interval);
  },[])

    return (
        <>
            

 <Layout>


         <div>

         <div className='row mNew-2'>
                <div className="col-8">
                 <div className="row" style={{
                    width:'100%',
                    paddingLeft: '20px',
                    paddingTop: '1%',
                    height:'75%',
                    backgroundColor: '#ffd200',
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15}}>
                          <span onClick={()=> back()} id="back">
                            <i class="fa fa-arrow-left d-flex justify-content-center backIcon"></i> 
                          </span>
                            <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>Make Order
                            </p>
                    </div>
                    </div>
                    <div className="col-4">
                            <img src={logo} className="img-fluid rounded mx-auto d-block vIcon"/>
                     </div>
                </div>

     {isLoading ? (<> 
        <div className="col-12" style={{marginTop:'20%'}}>
            <div class="d-flex justify-content-center">
                <div class="spinner-grow" role="status">
                    <span class="sr-only">Loading...</span>
                </div> 
            </div>
            </div>
     
       </>) :(<>
                <div className="col-md-12 mt-5">
 
                    <div className="row">
    <div className="col-1"></div>
    <div className="col-4 p-3 mrLg card" style={{background:'#ffd200'}}>
       <div>
         <img src={`${configData.SERVER_URL}/images/products/${productSelectedImage}`} className="img-fluid mx-auto d-block"  width='15%'/>
         <p className="text-center" style={{color: "#000", fontSize: 20, fontWeight: "bold"}}>
           {productSelected}
         </p>
       </div>
    </div>
    <div className="col-7"></div>
  </div>

                    <div className="row">
<div className="col-md-1"></div>
<div className="col-md-11">
<form  onSubmit={handleSubmit(next)} enctype="multipart/form-data" id="submit">
  <div className="row mt-3">
    <div className="col-12 p-5" style={{background:'#fff'}}>
        <div className="row">
            <div className="col-6">
              <p className="orderTitle">QUANTITY (LTR/KG)</p>
              <div className="boxCad border-top">
                  <div className="row mt-4 border-right">
                    <div class="col-7">
                      <input type="text" class="form-control" placeholder="E.g 20" name="litres" style={{fontWeight:'bold', fontSize:'20px',}} 
                      ref={register({ required: true })} />
                      </div>
                    <div class="col-5">
                          <h4 style={{fontWeight:'bold', marginTop:'40%', fontSize:'1rem'}}>{productUnit}</h4>
                      </div>
                    <div class="col-12">
                        {errors.litres && <div class="col-md-12 alert alert-danger err" role="alert">Product Quantity is Required</div>}
                      </div>
                  </div>
                </div>
            </div>

            <div className="col-6">
                <p className="orderTitle">PRICE / {productUnit}</p>
                <div className="boxCad border-top">
                <div className="row mt-4 justify-content-center align-items-center">
                  <h4 style={{fontWeight:'bold', marginTop:'10%', color:'#FFBC29', fontSize:'1rem'}}>#{pricePerLitre}</h4>
                </div>
              </div>
          </div>

        <div className="col-md-1"></div>
    </div>

                 <div className="row">
                     <div className="col-md-1"></div>

                     <div className="col-md-12">
                         <p className="orderTitle mt-5">TIME OF DELIVERY</p>
                       <div className=" border-top">
                           <div className="row">
                             <div class="col-12">
                               <div style={{width:'100%', paddingTop:'5%'}}>
                                 <DateTimePicker
                                   onChange={setTime}
                                   value={time}
                                   style={{width:'100%'}}
                                 />
                               </div>

                               </div>
                           </div>
                         </div>
                     </div>

                     <div className="col-md-1"></div>
                 </div>

                       <div className="row">
                           <div className="col-12">
                                 <button type="submit" class="btn btn-warn btn-block  my-4 waves-effect waves-light" >
                                     {disablebtn ? (<>Please wait</>) : (<>Next</>)}
                               </button>
                               </div>

                         </div>


               </div>
           </div>
         </form>
     </div>
 </div>

                 </div>
            </> )}









         </div>

 </Layout>
 

   

        </>
    );
}

export default MakeOrder;