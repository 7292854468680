
import React, {Component} from 'react';
import Header from '../main/header';
import logo from '../assets/img/logo2.png';

export class MyOrder extends Component {

    
  render() {
    return (

         
		<div className="">
             
             <Header/>
            
                <div className="container">

                  <div className="row mt-lg-5 mb-lg-5">

                        <div className="col-md-12">
                            <div className="row">
                                
                                <div className="col-md-12" id="contentDash">
                                    <div className="row">

                                        <div className="col-md-12 mt-2">
                                            <p className="title">TRANSACTIONS DETAILS</p>
                                        </div>

                                 
                                        <div class="col-md-12 mt-4">
                                            <div class="row">
                                                <div class="col-md-6">
                                                   <div class="card p-4" id="">
                                                       <div class="row">
                                                           <div class="col-3"></div>
                                                           <div class="col-6">                              
                                                               <span class="text-center font-weight-bold mt-2">Transaction ID:</span>
                                                               <span class="p"> 07035814787</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-3"></div>
                                                           <div class="col-md-12 mt-5">
                                                               <span class="text-center font-weight-bold">Date:</span>
                                                               <span class="p" style={{float:"right"}}>25-July-2020</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Customers Name:</span>
                                                               <span class="p" style={{float:"right"}}>Precious Vwarho</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Agents Name:</span>
                                                               <span class="p" style={{float:"right"}}>Precious Vwarho</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Product Type:</span>
                                                               <span class="p" style={{float:"right"}}>Fuel</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">NO of Litres:</span>
                                                               <span class="p" style={{float:"right"}}>20</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Price:</span>
                                                               <span class="p" style={{float:"right"}}>#2000</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12">
                                                               <span class="text-center font-weight-bold">Service Charge:</span>
                                                               <span class="p" style={{float:"right"}}>#200</span>
                                                               <hr/>
                                                           </div>
                                                           <div class="col-md-12 mt-4">
                                                               <span class="text-center font-weight-bold">GRAND TOTAL:</span>
                                                               <span class="p" style={{float:"right", fontSize:"30px"}}>#20000</span>
                                                           </div>
                                                       </div>
                                                    </div>
                                                </div>
                                               
                                                <div class="col-md-5">
                                                   <div class="card p-4" id="">
                                                      <div className="row">
                                                           <div className="col-md-12">
                                                                <img className="img-fluid mx-auto d-block" src={logo} width="100"/>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <h6 className='text-center mt-3 profile'>Precious Vwarho</h6>
                                                                <p className='text-center'>
                                                                07035814787</p>
                                                            </div>
                                                            
                                                            <div class="col-md-12 mt-2">
                                                                <span class="text-center font-weight-bold">Request Time:</span>
                                                                <span class="p" style={{float:"right"}}>12:00am</span>
                                                                <hr/>
                                                            </div>
                                                            
                                                            <div class="col-md-12 mt-2">
                                                                <span class="text-center font-weight-bold">Delivery Time:</span>
                                                                <span class="p" style={{float:"right"}}>12:10am</span>
                                                                <hr/>
                                                            </div>
                                                      </div>                                                       
                                                    </div>
                                                </div>
                                                <div class="col-md-1"></div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    
               
                        </div>
                    
                    </div>
    
              </div>
         
          

    );
  }

}