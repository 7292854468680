import React, { useEffect, useRef, useState, useContext } from "react";
import GoogleMapReact from 'google-map-react';

import Marker from './Marker.tsx';
 
import { useHistory } from 'react-router-dom';
import {Context} from "../../../Store";

const {REACT_APP_API_URL} = process.env;

const GPlace = () => {
  const history = useHistory();
  const placeInputRef = useRef(null);
  const [place, setPlace] = useState(null);
  const [state, setState] = useContext(Context);
  const [zoom, setZoom] = useState(14);
  const [loading, setLoading] = useState(false);


  // initialize the google place autocomplete
  const initPlaceAPI = () => {
    let autocomplete = new window.google.maps.places.Autocomplete(placeInputRef.current);
    new window.google.maps.event.addListener(autocomplete, "place_changed", function () {
      let place = autocomplete.getPlace();
    //   setAddress(place.formatted_address);
    //   setlatitude(place.geometry.location.lat());
    //   setlongitude(place.geometry.location.lng());
      setPlace({
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      });
       makeOrder(place.formatted_address, place.geometry.location.lat(), place.geometry.location.lng());
     
    });
  };

  useEffect(() => {
    initPlaceAPI();
  }, []);

  const makeOrder = async (address, lat, lng) => {
   
    window.localStorage.setItem("address", address);
    window.localStorage.setItem("lat", lat);
    window.localStorage.setItem("long", lng);


    history.push('/OrderSummary')
  };


  
  const lat = localStorage.getItem("lat");
  const long = localStorage.getItem("long");

  const currentLocation = () => {

    // var latlng = new google.maps.LatLng(lat, long);
    // // This is making the Geocode request
    // var geocoder = new google.maps.Geocoder();
    // geocoder.geocode({ 'latLng': latlng },  (results, status) => {
    //     if (status !== google.maps.GeocoderStatus.OK) {
    //         alert(status);
    //     }
    //     // This is checking to see if the Geoeode Status is OK before proceeding
    //     if (status == google.maps.GeocoderStatus.OK) {
    //         console.log(results);
    //         var address = (results[0].formatted_address);
    //         console.log()
    //     }
    // });

    const KEY = "AIzaSyDbZOsA6Oo5dfsG4HE9jkUYmEznCwiZrEY";
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${REACT_APP_API_URL}`;
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const address = data.results[0].formatted_address;
        window.localStorage.setItem("address", address);
        window.localStorage.setItem("lat", lat);
        window.localStorage.setItem("long", long);
        // console.log(data);
        history.push('/OrderSummary');

      })
        
  }
       
  useEffect(() => { 
    const token =  window.localStorage.getItem("token");
    if (!token || !lat){ 
        window.location = window.location.origin + "/";
        return
    }
  }, []);    



  return (
    <>

    
  {loading ? (<> 
         <div className="col-12" style={{marginTop:'50%'}}>
             <div class="d-flex justify-content-center">
                    <div class="spinner-grow" role="status">
                      <span class="sr-only">Loading...</span>
                    </div> 
                </div>
            </div>
                    </>) : (<>   

    <div style={{ height: '15vh', width: '100%', zIndex:1000 }}>
        <p style={{color:'#000', fontWeight: "bold" }}>
          Whats your delivery location?</p>
        <input style={{
            backgroundColor: "#ededed",
            width:'80%',
            borderBottomWidth: 0,
            marginBottom:'5%',
            borderTopWidth: 0}} type="text" ref={placeInputRef} />
       </div>


      <div>
           <p onClick={()=>currentLocation()} style={{paddingLeft:20, paddingRight:20, color:'#000', fontWeight: "bold", position:'absolute', bottom:'5%', zIndex:4000, backgroundColor:'#ffd200', cursor:'pointer'}}>Use current location</p>
        </div>
      
      <div style={{ height: '80vh', width: '100%'}}>
       
                <div style={{ height: '100%', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: `${REACT_APP_API_URL}`}}
                        defaultCenter={{lat: parseFloat(lat), lng: parseFloat(long)}}
                        defaultZoom={zoom}>
                        <Marker
                            lat={lat}
                            lng={long}
                            text="My Marker"/>
                        </GoogleMapReact>
                  </div>
   
            </div>
       </>) }
    </>
  );
};

export default GPlace;