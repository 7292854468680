import React, {Component} from 'react';

class Footer extends Component {

    
  componentDidMount() {
    
  } 
    
  render() {
    return (
  <div>

  

<section className="container-fluid" id="order">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <p className="pt-2" style={{fontWeight:'600', float:'right'}}>YOU CAN ALSO ORDER FOR YOUR PRODUCTS BY CLICKING THE MAKE ORDER BUTTON</p>
                         </div>
                        <div className="col-md-4">
                            <a href="/" className="btn btn-black btn-sm" style={{float:"left"}}>Make Order</a>
                         </div>
                     </div>
                 </div>   
             </section>


             <footer class="page-footer center-on-small-only">

                    <div class="footer-copyright p-b-5">
                        <div class="container-fluid">
                            © 2021 Copyright: <a href="http://">  ENERGIE </a>
                        </div>
                    </div>

                </footer>



            

  </div>         

    )
  }

}

export default Footer;