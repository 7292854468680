import React, {useEffect} from 'react';
import Map from './component/GMap';
import logo from '../../assets/img/logo2.png';
import Layout from './Layout';

function MyLocation() {


    const back = () =>{
        window.location = window.location.origin + "/MakeOrder";
     }
     
     useEffect(() => { 
        const token =  window.localStorage.getItem("token");
        if (!token){ 
            window.location = window.location.origin + "/";
            return
        }
      }, []);    
    
    

    return (
        <>


           <Layout>


                        <div>

                        <div className='row mNew-2'>
                                <div className="col-8">
                                <div className="row" style={{
                                    width:'100%',
                                    paddingLeft: '20px',
                                    paddingTop: '1%',
                                    height:'75%',
                                    backgroundColor: '#ffd200',
                                    borderTopRightRadius: 15,
                                    borderBottomRightRadius: 15}}>
                                            <span onClick={()=> back()} id="back">
                                            <i class="fa fa-arrow-left d-flex justify-content-center backIcon"></i> 
                                            </span>
                                            <p style={{fontSize: 18, color: "#000", fontWeight: "bold"}}>Delivery Location
                                            </p>
                                    </div>
                                    </div>
                                    <div className="col-4">
                                            <img src={logo} className="img-fluid rounded mx-auto d-block vIcon"/>
                                    </div>
                                </div>
                            
                                <Map/>

                        </div>

           </Layout>
 

            
        </>
    );
}

export default MyLocation;